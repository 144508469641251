import React from 'react';

import BigTableDataForm from "components/bng/pages/bigTable/menus/BigTableDataForm";

class DataTab extends React.Component {
    render() {
        const {datasourceConfig, datasourceName, onDataFormSubmit, setOpenAccordion, dataFormSubmitted} = this.props;

        const shouldResetFilters = this.props.columns.some(
            (c) => c.clickConfig.action === "APPLY_FILTER" && !c.clickConfig.props?.applyOnObject
        );

        return (
            <div className="BngBigTable__menuTab ObjectRightMenuAccordion"
                 style={{backgroundColor: '#efefef'}}>
                <BigTableDataForm
                    onSubmit={onDataFormSubmit}
                    datasourceConfig={datasourceConfig}
                    setOpenAccordion={setOpenAccordion}
                    datasourceName={datasourceName}
                    dataFormSubmitted={dataFormSubmitted}
                    filters={this.props.filters}
                    projectFilters={this.props.projectFilters}
                    shouldResetFilters={shouldResetFilters}
                />
            </div>
        );
    }
}

export default DataTab;