import './BigTableDataForm.css';

import React from 'react';
import PropTypes from 'prop-types';

import Button from "components/ui/Button";
import {BngSelectSearch} from "components/bng/form/BngSelectSearch";
import UiMsg from "components/ui/UiMsg";
import NewAnalysisService from "components/bng/pages/newAnalysis/NewAnalysisService";
import {UiBlocker} from "components/bng/ui/UiBlocker";
import ContextEnhancer from "components/ContextEnhancer";
import FilterDialog from "components/filter/FilterDialog";
import AccordionList from "components/ui/AccordionList";
import Accordion from "components/ui/Accordion";
import BngCheckbox from "components/bng/form/BngCheckbox";
import {Axios} from "commonUtils";
import Api from "components/Api";
import BngSearch from "components/bng/ui/BngSearch";
import Icon from "components/ui/common/Icon";
import {iconForDimensionType} from "components/bng/pages/bigTable/functions";

window.__BngBigTableFormCache = {};
const CACHE = window.__BngBigTableFormCache;

const BigTableDataForm = ContextEnhancer(
    class BigTableDataFormInner extends React.PureComponent {

        static propTypes = {
            className: PropTypes.string,
            service: PropTypes.object,
            onSubmit: PropTypes.func,
            datasourceConfig: PropTypes.object,
            setOpenAccordion: PropTypes.func,
            datasourceName: PropTypes.string,
            dataFormSubmitted: PropTypes.bool,
            projectFilters: PropTypes.array,
            shouldResetFilters: PropTypes.bool,
        };

        static defaultProps = {
            service: NewAnalysisService,
            onSubmit: _.noop,
            datasourceConfig: null,
            setOpenAccordion: _.noop,
            datasourceName: '',
            dataFormSubmitted: false,
            projectFilters: [],
            shouldResetFilters: false,
        };

        state = {
            sources: [],
            cubes: [],
            loading: false,
            showCancel: false,
            cancelToken: null,

            showModal: false,
            selectingHierarchy: false,
            selectedSourceField: null,
            modalItems: [],
            timeDimension: false,
            dynamicMembers: [],
            selectedSourceFieldFilter: {
                type: 'REGULAR',
                caption: '',
                filterType: 'MULTIPLE_SELECTION',
                selectedMembers: []
            },

            form: {
                projectName: this.props.context.project.name,
                datasource: this.props.datasourceName || this.props.datasourceConfig.cube || '',
                cube: this.props.datasourceConfig.cube || '',
                sourceFields: [],
                customQuery: false,
                query: '',
            },

            sourceFieldFilter: '',
        };

        async componentDidMount() {
            try {
                const sources = await this.props.service.findSources({projectId: this.props.context.project.id});
                this.setState({sources});

                if (this.props.datasourceName) {
                    const cubes = await this.datasourceChanged(this.props.datasourceName);
                    this.setState({cubes});

                    if (this.props.datasourceConfig.cube) {
                        let sourceFields = _.cloneDeep(await this.loadCubeData({cube: this.props.datasourceConfig.cube}));
                        //Seleciona os campos que foram selecionados na criacao via analises assistidas
                        if (!_.isEmpty(this.props.datasourceConfig.sourceFields)) {
                            this.props.datasourceConfig.sourceFields.filter(sf => sf.selected).forEach(sf => {
                                const match = sourceFields.find(f => sf.field === f.field);
                                if(match) {
                                    match.selected = true;
                                }
                            });
                        }

                        if (this.props.filters && this.props.filters.length > 0) {
                          sourceFields.forEach((sf) => {
                            const mdxGlobalFilter = this.props.projectFilters.find((fd) =>
                              fd.mdxFilters.find(
                                (mdxFilter) =>
                                  mdxFilter.datasource === this.state.form.datasource &&
                                  (mdxFilter.dimension === sf.field || mdxFilter.dimension === `BIMF${sf.field}`)
                              )
                            );
                            if (!mdxGlobalFilter) {
                              return;
                            }
                            if (this.props.shouldResetFilters) {
                              return;
                            }
                            const matchingFilter = this.props.filters.find(
                              (filter) => filter.id === mdxGlobalFilter.id && filter.members?.length > 0
                            );
                            if (!matchingFilter) {
                              return;
                            }

                            matchingFilter.members
                              .filter((member) =>
                                  !sf.selectedMembers.some(
                                    (existingMember) => existingMember.value === member
                                  ))
                              .forEach((member) => {
                                sf.selectedMembers.push({
                                  description: null,
                                  disabled: false,
                                  escape: true,
                                  field: sf.field,
                                  label: member.replaceAll(/[|]/g, ''),
                                  value: member,
                                });
                              });
                          });
                        }

                        this.setState({
                            form: {
                                ...this.state.form,
                                sourceFields: sourceFields
                            }
                        });

                        if (!this.props.dataFormSubmitted && sourceFields.some(sf => sf.selected)) {
                            await this.onFormSubmit(true);
                        }
                    }
                }

            } catch (e) {
                console.error(e);
                UiMsg.ajaxError(null, e);
            } finally {
                this.setState({loading: false});
            }

        }

        onChange = async (field, value) => {
            let current = {...this.state.form};
            let next = {...this.state.form, [field]: value};

            if (next.datasource !== current.datasource) {
                const cubes = await this.datasourceChanged(next.datasource);
                next = {
                    ...next,
                    datasource: _.isEmpty(cubes) ? '' : next.datasource,
                    cube: _.isEmpty(cubes) ? '' : cubes[0].value,
                    sourceFields: []
                };
            }

            // Load source fields when cube is selected or changed
            const emptySourceFields = (current.sourceFields.length === 0 && next.datasource && next.cube);
            const cubeChanged = (next.cube && current.cube && next.cube !== current.cube);

            if (emptySourceFields || cubeChanged) {
                this.setState({loading: true});
                try {
                    let sourceFields = await this.loadCubeData(next);
                    next = {
                        ...next,
                        sourceFields: sourceFields
                    };
                } catch (e) {
                    console.error(e);
                    UiMsg.ajaxError(null, e);
                } finally {
                    this.setState({loading: false});
                }
            }

            this.setState({form: next});

        };

        datasourceChanged = async (sourceName, useCache = false) => {
            this.setState({loading: true});
            let cubes = [];
            if (sourceName) {
                try {
                    const cacheKey = `datasourceChanged${sourceName}`
                    if (useCache && CACHE.hasOwnProperty(cacheKey)) {
                        cubes = CACHE[cacheKey];
                    } else {
                        cubes = await this.props.service.sourceCubes({
                            projectId: this.props.context.project.id,
                            sourceName
                        });
                        CACHE[cacheKey] = cubes;
                    }
                } catch (e) {
                    console.error(e);
                    UiMsg.ajaxError(null, e);
                }
            }
            await this.setState({
                cubes,
                loading: false,
            });
            return cubes;
        };

        async loadCubeData({cube}, useCache = false) {
            this.setState({
                loading: true,
            });
            try {
                const cacheKey = `loadCubeData${cube}`;

                let result;

                if (useCache && CACHE.hasOwnProperty(cacheKey)) {
                    result = CACHE[cacheKey];
                } else {
                    const sourceFields = await Api.BigTable.findFieldsInfo(this.props.context.project.name, cube);
                    result = {sourceFields};
                    CACHE[cacheKey] = result;
                }

                const sourceFields = this.props.datasourceConfig?.sourceFields || [];
                return result.sourceFields.map(f => {
                    const matchSourceField = sourceFields.find((s) => s.field === f.value);
                    const hierarchy = (matchSourceField ?  matchSourceField.hierarchy : '') || '';

                    const selectedHierarchy = [];
                    if(hierarchy) {
                        selectedHierarchy.push({value: hierarchy, label: hierarchy, disabled: false});
                    }

                    return {
                        ...f,
                        selected: matchSourceField ? matchSourceField.selected : false,
                        field: f.value,
                        hierarchy,
                        axis: f.type === 'Measure' ? 'COLUMN' : 'ROW',
                        selectedMembers: matchSourceField ? [...(matchSourceField.selectedMembers || [])] : [],
                        selectedHierarchy
                    }
                });
            } catch (e) {
                console.error(e);
                UiMsg.ajaxError(null, e);
            } finally {
                this.setState({loading: false});
            }

        }

        renderFields(fieldType, label) {
            let fields = this.state.form.sourceFields.filter(f => f.type === fieldType && f.visible);

            if(this.state.sourceFieldFilter) {
                const lowerSourceFieldFilter = this.state.sourceFieldFilter.toLowerCase();
                fields = fields.filter(f => f.label.toLowerCase().includes(lowerSourceFieldFilter));
            }

            if (fields.length === 0) {
                return null;
            }

            fields.sort(function (a,b) {
                return a.label.localeCompare(b.label);
            });

            return (
                <Accordion title={label} startOpen={true}>
                    <div className="BngBigTable__fieldList">
                        {fields.map((field) => {

                            let found = this.state.form.sourceFields.find(f => f.field === field.value);
                            let index = this.state.form.sourceFields.indexOf(found);

                            let onChange = () => {
                                const v = [].concat(this.state.form.sourceFields);
                                v[index].selected = !v[index].selected;
                                this.setState({
                                    form: {
                                        ...this.state.form,
                                        sourceFields: v
                                    }
                                });
                            }

                            let openFilterDialog = async (timeDimension = false, hierarchies = false) => {
                                let queryParams = {
                                    projectName: this.state.form.projectName,
                                    datasource: this.state.form.datasource,
                                    cube: this.state.form.cube,
                                    dimension: field.value,
                                    timeDimension: timeDimension
                                }

                                if (field.hierarchy != '') {
                                    queryParams.hierarchy = field.hierarchy;
                                }

                                try {
                                    const membersInfo = await Api.BigTable.filterMembersInfo(queryParams, hierarchies);
                                    this.setState({
                                        selectedSourceField: field,
                                        selectedSourceFieldFilter: {
                                            type: (timeDimension && !hierarchies) ? 'TIME' : 'REGULAR',
                                            filterMode: hierarchies ? 'NORMAL' : undefined,
                                            caption: field.label,
                                            filterType: (timeDimension || hierarchies) ? 'SINGLE_SELECTION' : 'MULTIPLE_SELECTION',
                                            selectedMembers: hierarchies ? field.selectedHierarchy : field.selectedMembers,
                                            axis: field.axis,
                                            restrictionType: 'SHOW_SELECTED'
                                        },
                                        timeDimension: timeDimension,
                                        modalItems: !timeDimension ? membersInfo : [],
                                        dynamicMembers: timeDimension ? membersInfo : [],
                                        showModal: true,
                                        selectingHierarchy: hierarchies
                                    });
                                } catch (e) {
                                    console.error(e);
                                }
                            }

                            return (
                                <div key={index} className="BngBigTable__fieldList__field">
                                    <Icon icon={iconForDimensionType(field.type)} className='structure-icon'/>
                                    <span onClick={onChange}>{field.label}</span>

                                    <div className="BngBigTable__fieldList__field--container">

                                        <BngCheckbox
                                            field={{value: field.selected}}
                                            onChange={onChange}
                                        />

                                        <div>
                                            {(field.type === "Regular") &&
                                            <i className={`material-icons BngBigTable__dataform-icon ${field.selectedMembers.length > 0 ? 'active' : ''}`}
                                               onClick={() => openFilterDialog()}>{'filter_alt'}</i>
                                            }

                                            {fieldType === 'TimeDimension' && (
                                                <>
                                                    <i className={`material-icons BngBigTable__dataform-icon ${field.hierarchy != '' ? 'active' : ''}`}
                                                       onClick={() => openFilterDialog(false, true)}>{'query_builder'}</i>
                                                    <i className={`material-icons BngBigTable__dataform-icon ${field.selectedMembers.length > 0 ? 'active' : ''}`}
                                                       onClick={() => openFilterDialog(true)}>{'filter_alt'}</i>
                                                </>
                                            )}

                                        </div>

                                    </div>

                                </div>
                            )
                        })}
                    </div>

                </Accordion>
            );
        }

        isFormValid = () => {
            let measures = this.state.form.sourceFields.filter(f => f.type === 'Measure' && f.visible && f.selected);
            let regulars = this.state.form.sourceFields.filter(f => f.type === 'Regular' && f.visible && f.selected);
            let timeDimensions = this.state.form.sourceFields.filter(f => f.type === 'TimeDimension' && f.visible && f.selected);
            return measures.length > 0 && (regulars.length > 0 || timeDimensions.length > 0);
        }

        onFormSubmit = async (changeTab = false) => {

            if (!this.isFormValid()) {
                UiMsg.warn(this.props.context.msg.t('select_dimensions'), '');
                return;
            }

            const data = {...this.state.form}
            this.setState({loading: true, showCancel: true});

            let dataClone = Object.assign({}, data);
            dataClone.sourceFields = dataClone.sourceFields.filter(f => f.selected || f.selectedMembers.length > 0);

            try {
                const cancelSource = Axios.generateCancelToken();
                this.setState({cancelToken: () => cancelSource.cancel()});
                const queryResult = await Api.BigTable.executeQuery(dataClone, cancelSource);
                this.props.onSubmit(queryResult, dataClone.sourceFields, dataClone);
            } catch (e) {
                console.error('Error on onFormSubmit()', e);
                let details = e;
                if(e.isAxiosError && e.response.status === 504) {
                    details = this.props.context.msg.t('query.gateway.timeout.msg')
                }
                UiMsg.ajaxError(this.props.context.msg.t('BigTableApp.MdxQueryError'), details);
            } finally {
                this.setState({loading: false, showCancel: false});

                if (changeTab) {
                    this.props.setOpenAccordion('BigTableLayoutMenuItem');
                }
            }
        };

        onModalClose = () => {
            this.setState({
                showModal: false
            });
        }

        render() {
            const {form, loading, sources, cubes} = this.state;
            const {context} = this.props;

            return (
                <>
                    <AccordionList className="ObjectRightMenuAccordion BigTableDataAccordion">
                        <div className={"BngBigTable__dataForm"}>
                            <div className="BngForm">
                                <UiBlocker block={loading}>
                                    <div className="p-10">
                                        <label
                                            className="control-label">{this.props.context.msg.t('header_menu_in_memory')}</label>
                                        <BngSelectSearch
                                            options={sources}
                                            defaultPreviewIcon="insert_chart"
                                            groupedOpts={true}
                                            form={{setFieldValue: (name, t) => this.onChange('datasource', t)}}
                                            field={{value: form.datasource, onChange: _.noop}}
                                        />

                                        {cubes.length > 1 &&
                                        <>
                                            <label className="control-label">{this.props.context.msg.t('cube')}</label>
                                            <BngSelectSearch
                                                options={cubes}
                                                form={{setFieldValue: (name, t) => this.onChange('cube', t)}}
                                                field={{value: form.cube, onChange: _.noop }}
                                            />
                                        </>
                                        }

                                    </div>

                            {form.datasource !== '' && (
                                <AccordionList className="BngBigTable__firstLevelAccordion">
                                    <BngSearch className={'BigTableSearch'}
                                               alwaysOpen={true}
                                               placeholder={this.props.context.msg.t('header.search')}
                                               onChange={sourceFieldFilter => this.setState({sourceFieldFilter})}
                                               inputAutoComplete={false}
                                    />
                                    {this.renderFields('Measure', this.props.context.msg.t('measures'))}
                                    {this.renderFields('Regular', this.props.context.msg.t('regulars'))}
                                    {this.renderFields('TimeDimension', this.props.context.msg.t('TIME'))}
                                    {this.renderFields('Url', this.props.context.msg.t('dimension.type.url'))}
                                </AccordionList>
                            )}
                                    {(!this.isFormValid() && this.state.form.sourceFields.length !== 0) && (
                                        <p className="Explanation" style={{color: '#d15b47', margin: 10, maxWidth: 290}}>{context.msg.t('select_dimensions')}</p>
                                    )}

                                </UiBlocker>
                                {this.state.showModal && (
                                    <FilterDialog
                                        sourceField={this.state.selectedSourceField}
                                        onClose={this.onModalClose}
                                        onChange={(data, force, additionalProps) => {
                                            const clone = [].concat(this.state.form.sourceFields);
                                            clone.forEach(v => {
                                                if (v.field === this.state.selectedSourceField.value) {
                                                    if (this.state.selectingHierarchy) {
                                                        v.selectedHierarchy = data;
                                                        v.hierarchy = data?.[0]?.value || '';
                                                    } else {
                                                        v.selectedMembers = data
                                                    }
                                                }
                                            })
                                            this.setState({
                                                form: {
                                                    ...this.state.form,
                                                    sourceFields: clone
                                                }
                                            });
                                            additionalProps.closeModal();
                                        }}
                                        maxElements={20}
                                        items={this.state.modalItems}
                                        filter={this.state.selectedSourceFieldFilter}
                                        enableModeChange={this.state.timeDimension}
                                        dynamicItems={this.state.timeDimension ? this.state.dynamicMembers : []}
                                        availableDateRange={{
                                            max: "2106-12-31T00:00:00",
                                            min: "1999-01-01T00:00:00",
                                            valid: true,
                                        }}
                                        containRestriction={false}
                                        restrictionType={'SHOW_SELECTED'}
                                        restrictionMembers={[]}
                                        dataRestrictionMembers={[]}

                                    />
                                )}
                            </div>
                        </div>
                    </AccordionList>
                    <Accordion className="ApplyBigTableData" customTitle={() => null}>
                        <div className="row-fluid">
                            <div className="span12 text-right btn-fix p-10">
                                {this.state.showCancel && (
                                    <Button type="submit" className={'bng-button cancel'} onClick={() => {
                                        if (this.state.cancelToken) {
                                            this.state.cancelToken()
                                        }
                                    }}>
                                        {this.props.context.msg.t('cancel')}
                                    </Button>
                                )}
                                <Button type="button" className={'bng-button save'} onClick={() => this.onFormSubmit()}>
                                    {this.props.context.msg.t('apply_button')}
                                </Button>
                            </div>
                        </div>
                    </Accordion>
                </>

            );
        }
    }
);

export default BigTableDataForm;
