import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

const useBuildRouteAuthFields = (name, authType, context) => {
  const [routeAuthFields, setRouteAuthFields] = useState([]);

  useEffect(() => {
    if (isEmpty(authType)) {
      return;
    }
    const tempRouteAuthFields = [];
    switch (authType.name) {
      case 'token':
        tempRouteAuthFields.push({
          name: 'token',
          label: context.msg.t('bim.integration.token'),
          type: 'text',
        });
        break;
      case 'multiToken':
        tempRouteAuthFields.push({
          label: context.msg.t('bim.integration.access.token'),
          name: 'multiToken',
          type: 'text',
          multipleItems: {
            keyLabel: context.msg.t('bim.integration.token'),
            valueLabel: context.msg.t('in_memory_description'),
          },
        });
        break;
      case 'basic_auth':
        tempRouteAuthFields.push(
          {
            name: 'user',
            label: context.msg.t('bim.integration.user'),
            type: 'text',
          },
          {
            name: 'password',
            label: context.msg.t('bim.integration.password'),
            type: 'password',
          },
        );
        break;
      case 'auth_path':
        tempRouteAuthFields.push(
          {
            name: 'authorization',
            label: 'Authorization',
            type: 'text',
          },
          {
            name: 'token',
            label: 'Token',
            type: 'text',
          },
        );
        break;
      case 'token_totvs_chef':
        tempRouteAuthFields.push(
          {
            name: 'user',
            label: context.msg.t('bim.integration.user'),
            type: 'text',
          },
          {
            name: 'pass',
            label: context.msg.t('bim.integration.password'),
            type: 'password',
          },
          {
            name: 'serial',
            label: context.msg.t('bim.integration.serial'),
            type: 'text',
          },
        );
        break;
      case 'vtex':
        tempRouteAuthFields.push(
          {
            name: 'accountName',
            label: 'accountName',
            type: 'text',
          },
          {
            name: 'AppKey',
            label: 'AppKey',
            type: 'text',
          },
          {
            name: 'AppToken',
            label: 'AppToken',
            type: 'text',
          },
        );
        break;
      case 'omie':
        tempRouteAuthFields.push({
          name: 'app_key',
          label: 'App Key',
          type: 'text',
          multipleItems: {
            keyLabel: 'App Key',
            valueLabel: 'App Secret',
          },
        });
        break;
      case 'url':
        tempRouteAuthFields.push({
          name: 'link',
          label: context.msg.t('bim.integration.base.url'),
          type: 'text',
        });
        break;
      case 'custom':
        authType.fields.forEach((field) => {
          tempRouteAuthFields.push({
            name: field.name,
            label: field.label,
            type: field.type,
          });
        });
        break;
      default:
        break;
    }

    if (name === 'toolsPharma') {
      tempRouteAuthFields[0].multipleItems = {
        keyLabel: 'Token',
      };
    }

    if (name === 'maisFrete') {
      tempRouteAuthFields.push({
        label: context.msg.t('cnpj_just_numbers'),
        name: 'cnpj',
        type: 'text',
        multipleItems: {
          keyLabel: 'CNPJ',
          valueLabel: context.msg.t('in_memory_description'),
        },
      });
    }

    setRouteAuthFields(tempRouteAuthFields);
  }, [authType]);

  return routeAuthFields;
};

export default useBuildRouteAuthFields;
