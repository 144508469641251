import { Axios } from 'commonUtils';

const endpoint = '/spr/ada/ai';

class AdaAiApi {
  async findAll() {
    return await Axios.getData(endpoint);
  }

  async save(assistant) {
    return await Axios.postData(endpoint, assistant);
  }

  async remove(id = 0) {
    return await Axios.delete(`${endpoint}/${id}`);
  }

  sendMessage = async (
    messageData = {
      message: '',
      accountId: 0,
      assistantId: '',
      threadId: '',
      userId: 0,
      name: '',
      description: '',
      instruction: '',
      model: '',
      type: '',
      temperature: '',
    }
  ) => {
    return await Axios.postData(`${endpoint}/message`, messageData);
  };
}

export default AdaAiApi;
