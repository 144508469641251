import styles from './AccAddonsTab.module.css';

import React from 'react';

import Api from 'components/Api';
import UiBlocker from 'components/bng/ui/UiBlocker';
import { AddonComponent } from 'components/bng/accounts/AddonsTab';
import { MODALS } from 'components/ui/redux/Actions';
import BngPasswordConfirmation from 'components/bng/ui/BngPasswordConfirmation';
import UiMsg from 'components/ui/UiMsg';
import useBimContext from 'components/hooks/useBimContext';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import useFetchData from 'components/hooks/useFetchData';
import Utils from 'components/Utils';
import { proposalUtils } from 'components/service/bng/AccountApi';

export default function AccAddonsTab({ account }) {
  const context = useBimContext();
  const dispatch = useReduxDispatch();

  const $addons = useFetchData(async () => {
    return await Api.Account.findAccountAddons(account.id);
  }, [account]);

  const $plan = useFetchData(async () => {
    return await Api.Account.findAccountPlan(account.id);
  }, [account]);

  const changeAddonEnabled = async (addonKey) => {
    const isAdmin = context.permissions.isAdmin();
    if (!isAdmin && !Utils.Users.isFinanceUser(context.user.email)) {
      return;
    }

    dispatch(
      MODALS.open(BngPasswordConfirmation, {
        onConfirm: async () => {
          try {
            const addonToEnable = $addons.data.allAddons.find((addon) => addon.id === addonKey);
            const enabledInAccount = !!$addons.data.accountAddons?.find((a) => a.id === addonKey);
            const defaultPricing = proposalUtils.getDefaultPricing(addonToEnable.billingAdditional?.pricings);
            const billingAdditional = {
              ...addonToEnable.billingAdditional,
              billingPricingId: defaultPricing?.id,
              agreedPricing: defaultPricing?.pricing,
            };
            const enable = !enabledInAccount;
            await Api.Account.changeAddonRequestStatus(addonKey, context.project.id, enable, null, billingAdditional);
            UiMsg.ok(context.msg.t(enable ? 'addon.enabled.successfully' : 'addon.disabled.successfully'));
            await $addons.reload();
          } catch (e) {
            console.error('Error on changeAddonEnabled()', { addonKey }, e);
            UiMsg.ajaxError(null, e);
          }
        },
      })
    );
  };

  return (
    <UiBlocker block={$addons.isLoading} className="AccAddonsTab h-100">
      <div className={styles.AccAddonsTab}>
        {$addons.data?.allAddons?.map((addon, index) => {
          const enabledInAccount = !!$addons.data.accountAddons?.find((a) => a.id === addon.id);
          return (
            <AddonComponent
              key={addon.id}
              addon={addon}
              index={index}
              enabledInAccount={enabledInAccount}
              activationRequests={$addons.data.activationRequests}
              accountId={account.id}
              withActionButtonFn={changeAddonEnabled}
              plan={$plan.data}
              fullWidth
            />
          );
        })}
      </div>
    </UiBlocker>
  );
}
