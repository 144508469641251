import './MyAccounts.css';

import React, {Component} from 'react';
import {Field, Form, Formik} from "formik";
import {connect} from "react-redux";

import ContextEnhancer from "components/ContextEnhancer";
import Dialog from "components/ui/Dialog";
import {bngYup} from "components/bng/form/yup/BngYup";
import {Tab, TabSet} from "components/ui/TabSet";
import FeaturesTab from "components/bng/accounts/FeaturesTab";
import ConsumptionTab, {calculateUsagePercent} from "components/bng/accounts/ConsumptionTab";
import ApplicationKeysTab from "components/bng/accounts/ApplicationKeysTab";
import AddonsTab from "components/bng/accounts/AddonsTab";
import {DefaultDialogActions} from "components/ui/FormUtils";
import Button from "components/ui/Button";
import {BngSelect} from "components/bng/form/BngSelect";
import Api from "components/Api";
import ProjectsTab from "components/bng/accounts/ProjectsTab";
import {BngField} from "components/bng/form/BngField";
import UiMsg from "components/ui/UiMsg";
import {MODALS} from "components/ui/redux/Actions";
import BngPasswordConfirmation from "components/bng/ui/BngPasswordConfirmation";

const MyAccountsSchema = bngYup(yup => yup.object().shape({
    account: yup.object().shape({
        id: yup.number().default(0),
        addons: yup.array().of(
            yup.object().shape({
                id: yup.string().default(''),
                enabled: yup.bool().default(false),
                billingAdditional: yup.object().default({}).nullable(),
            })
        ),
        plan: yup.object().shape({
            description: yup.string().default(''),
            id: yup.number().default(0),
            name: yup.string().default(''),
            publicPlan: yup.bool().default(false),
        }),
        accountMetrics: yup.array().of(
            yup.object().shape({
                additionalCapacity: yup.number().min(0),
            }),
        ),
        config: yup.object().shape({
            canRemoveProject: yup.bool().default(false),
            showBimAcademy: yup.bool().default(true),
            showBimStore: yup.bool().default(true),
            hideAddonsPage: yup.bool().default(false),
        }),
    }),
}));

const BngPlanButtons = ({context, plans, selectPlan, changeSelectPlan, resources}) => (
    <div>
        <label>{context.msg.t('selected.plan')}</label>
        {selectPlan.publicPlan ?
            <div style={{display: 'flex'}}>
                {plans.map(plan => {
                    const isOverload = hasResourcesOverload(resources, plan);
                    return (
                        <Button key={plan.id} disabled={isOverload}
                                title={isOverload ? context.msg.t('plan.blocked.by.over.capacity') : ''}
                                className={`bng-button BngAccountButton ${plan.id === selectPlan.id ? 'active-plan' : ''}`}
                                onClick={(e) => isOverload ? false : changeSelectPlan(e, plan)}>
                            {plan.description}
                        </Button>
                    );
                })}
            </div> :
            <div style={{display: 'flex'}}>
                <Button className={`bng-button BngAccountButton active-plan`} style={{width: '50%'}}>
                    <span>{context.msg.t('custom.plan')}{` (${selectPlan.name})`}</span>
                </Button>
                <Button className={`bng-button BngAccountButton`} style={{width: '50%'}}
                        onClick={(e) => changeSelectPlan(e, plans[plans.length - 1])}>
                    <span>{context.msg.t('change.to.public.plan')}</span>
                </Button>
            </div>
        }
    </div>
);

const RESOURCES_CHECK_OVERLOAD = ["PROJECTS", "STORAGE_SPACE"];

const hasResourcesOverload = (resources, plan) => {
    for (let planResource of plan.resources) {
        if (!RESOURCES_CHECK_OVERLOAD.includes(planResource.resource)) continue;
        const result = resources.find(resource => resource.resource === planResource.resource);
        if (result) {
            const usagePercent = calculateUsagePercent(result.currentUsage, planResource.capacity, result.additionalCapacity, planResource.customizable);
            if ((usagePercent > 100 || isNaN(usagePercent)) && !planResource.customizable) {
                return true;
            }
        }
    }
    return false;
};


class MyAccountsDialog extends Component {

    static propTypes = {};

    static defaultProps = {};

    state = {
        loading: true,
        accounts: [],
        applicationKeys: [],
        accountMetrics: {all: []},
        originAccounts: [],
        plans: [],
        projects: [],
        selectPlan: {},
        selectAccount: {},
        addons: [],
    };

    myAccountInitialValues = MyAccountsSchema.default();

    constructor(props) {
        super(props)
    }

    async componentDidMount() {
        try {
            const originAccounts = _.orderBy(await Api.Account.findAvailableDetails(), ['name'], ['asc']);

            const selectAccount = originAccounts.find(acc => acc.id === this.props.context.accountId) || originAccounts[0];

            const accounts = originAccounts.map(acc => ({
                value: acc.id,
                label: `${acc.id} - ${acc.name}`
            }));

            const result = await this.loadSelectAccountData(selectAccount);
            this.setInitialValues(result);

            const {plans, accountMetrics, projects, selectPlan, applicationKeys, addons} = result;
            this.setState({
                originAccounts,
                accounts,
                selectAccount,
                plans,
                accountMetrics,
                projects,
                selectPlan,
                applicationKeys,
                addons
            });
        } catch (e) {
            UiMsg.ajaxError('error', e);
        } finally {
            this.setState({loading: false});
        }
    }

    loadSelectAccountData = async (selectAccount = this.state.selectAccount) => {
        const plans = await Api.Account.findAvailablePlans();
        const accountMetrics = await Api.Account.findAccountMetrics(selectAccount.id);
        const projects = _.orderBy(await Api.Account.findAccountProjects({accountId: selectAccount.id}), ['name'], ['asc']);
        const applicationKeys = _.orderBy(await Api.Account.findApplicationKeys(selectAccount.id), ['name'], ['asc']);

        const addons = await Api.Account.findAccountAddons(selectAccount.id);
        const selectPlan = selectAccount.plan;
        return {plans, accountMetrics, projects, selectPlan, applicationKeys, selectAccount, addons};
    };

    setInitialValues = ({...props},) => {
        this.myAccountInitialValues.account = props.selectAccount;
        this.myAccountInitialValues.account.accountMetrics = props.accountMetrics.all;
        this.myAccountInitialValues.account.addons = props.addons.allAddons.map((addon) => {
            return {
                id: addon.id,
                enabled: props.addons.accountAddons.some(accountAddon => accountAddon.nameKey === addon.nameKey),
                billingAdditional: addon.billingAdditional,
            }
        });
    };

    changeSelectPlan = (e, plan, form) => {
        if (this.state.selectPlan.id !== plan.id) {
            e.preventDefault();
            form.setFieldValue("account.plan", plan);
            this.setState({selectPlan: plan});
        }
    };

    updateApplicationKey = (applicationKey, isDelete = false) => {
        let applicationKeys = this.state.applicationKeys.filter(apk => apk.id !== applicationKey.id);
        if (!isDelete) {
            applicationKeys.push(applicationKey);
        }
        applicationKeys = _.orderBy(applicationKeys, ['name'], ['asc']);
        this.setState({applicationKeys});
    };

    updateAccountState = async (selectAccount) => {
        const result = await this.loadSelectAccountData(selectAccount);
        this.setInitialValues(result);

        const {plans, accountMetrics, projects, selectPlan, applicationKeys, addons} = result;
        this.setState({
            selectAccount,
            plans,
            accountMetrics,
            projects,
            selectPlan,
            applicationKeys,
            addons
        });

        return result;
    }

    save = async (values, actions) => {
        this.setState({loading: true});
        try {
            const requestBody = {
                id: values.account.id,
                planId: values.account.plan.id,
                config: values.account.config,
                accountMetrics: values.account.accountMetrics
                    .map(am => ({
                        additionalCapacity: am.additionalCapacity,
                        resource: am.resource
                    })),
                addons: values.account.addons
            };
            const result = await Api.Account.saveAccount(requestBody);
            if (result.usersWithoutPhone.length > 0) {
                UiMsg.warn(
                    this.props.context.msg.t('attention'),
                    this.props.context.msg.t('email.sent.whatsapp.addon.activation', `<ul>${result.usersWithoutPhone.map(user => `<li>${user.email}</li>`).join('')}</ul>`)
                );
            }
            UiMsg.ok(this.props.context.msg.t('success'), this.props.context.msg.t(`account.update.success`));

            await this.updateAccountState(this.state.selectAccount);
            window.location.reload();
        } catch (e) {
            actions.setSubmitting(false);
            UiMsg.ajaxError('error', e);
            this.setState({loading: false});
        }
    };

    openPasswordConfirmation = (values, actions) => {
        this.props.dispatch(MODALS.open(BngPasswordConfirmation, {
            onConfirm: async () => {
                await this.save(values, actions);
            }
        }));
    };

    handleChangeAccount = async (event, form) => {
        this.setState({loading: true});

        form.handleChange(event);

        const selectAccount = this.state.originAccounts.find(acc => acc.id == event.currentTarget.value);

        try {
            const result = await this.updateAccountState(selectAccount);

            form.setFieldValue("account.id", result.selectAccount.id);
            form.setFieldValue("account.plan", result.selectAccount.plan);
            form.setFieldValue("account.config.canRemoveProject", result.selectAccount.config.canRemoveProject);
            form.setFieldValue("account.config.showBimAcademy", result.selectAccount.config.showBimAcademy);
            form.setFieldValue("account.config.showBimStore", result.selectAccount.config.showBimStore);
            form.setFieldValue("account.config.hideAddonsPage", result.selectAccount.config.hideAddonsPage);
            form.setFieldValue("account.accountMetrics", result.accountMetrics.all);
            form.setFieldValue("account.addons", result.addons.allAddons.map((addon) => {
                return {
                    id: addon.id,
                    enabled: result.addons.accountAddons.some(accountAddon => accountAddon.nameKey === addon.nameKey),
                    billingAdditional: addon.billingAdditional,
                }
            }));

            this.setState({loading: false});
        } catch (e) {
            UiMsg.ajaxError('error', e);
        } finally {
            this.setState({loading: false});
        }
    };

    render() {
        const {loading, accounts, applicationKeys, accountMetrics, plans, projects, selectAccount, selectPlan, addons} = this.state;
        const containAddonRequests = addons.activationRequests?.length > 0;

        return (
            <Dialog title={this.props.context.msg.t('account.management')}
                    className={`MyAccountsDialog xlarge`}
                    onClose={this.props.closeModal}
                    loading={loading}
                    contentFullWidth={true}>
                <Formik initialValues={this.myAccountInitialValues}
                        validationSchema={MyAccountsSchema}
                        onSubmit={this.openPasswordConfirmation}>
                    {({values, ...props}) => {
                        const accountMetricsValue = _.get(values, 'account.accountMetrics');
                        const isSomeOverload = accountMetricsValue ? accountMetricsValue.find(metric => {
                            if (!RESOURCES_CHECK_OVERLOAD.includes(metric.resource)) return false;
                            return metric.currentUsage > (metric.capacity + metric.additionalCapacity)
                        }) : false;
                        return (
                            <Form>
                                <div className="my-accounts-dialog-body">
                                    <div className="my-accounts-configuration">
                                        <div style={{paddingRight: 25}}>
                                            <Field name="account.id"
                                                   render={({field, form}) => (
                                                       <BngField
                                                           field={field}
                                                           form={form}
                                                           inputComponent={BngSelect}
                                                           onChange={(e) => this.handleChangeAccount(e, form)}
                                                           emptyOption={false}
                                                           options={accounts}
                                                           label={this.props.context.msg.t('account')}/>
                                                   )}/>
                                        </div>
                                        <Field name="account.plan"
                                               render={({field, form}) => (
                                                   <BngPlanButtons selectPlan={selectPlan} plans={plans}
                                                                   context={this.props.context}
                                                                   resources={accountMetrics.all}
                                                                   field={field}
                                                                   changeSelectPlan={(...props) => this.changeSelectPlan(...props, form)}/>
                                               )}/>
                                    </div>

                                    <TabSet internal={true} beforeChange={() => {
                                        return !loading
                                    }}>
                                        <Tab icon="bar_chart" label={this.props.context.msg.t('consumption')}>
                                            <ConsumptionTab selectPlan={selectPlan} accountMetrics={accountMetrics}/>
                                        </Tab>
                                        {(this.props.context.isInbox || this.props.context.permissions.isConsultant()) &&
                                            <Tab icon="style" label={this.props.context.msg.t('features')}>
                                                <FeaturesTab information={accountMetrics.featuresMetrics}/>
                                            </Tab>
                                        }

                                        <Tab icon="extension" label={this.props.context.msg.t('addons')} alert={containAddonRequests}>
                                            <AddonsTab accountAddons={addons.accountAddons}
                                                       allAddons={addons.allAddons}
                                                       activationRequests={addons.activationRequests}
                                                       selectAccount={selectAccount}
                                                       selectPlan={selectPlan}
                                            />
                                        </Tab>
                                        <Tab icon="vpn_key" label={this.props.context.msg.t('application.keys')}>
                                            <ApplicationKeysTab loading={(props) => this.setState({loading: props})}
                                                                accountId={selectAccount.id}
                                                                updateItem={this.updateApplicationKey}
                                                                applicationKeys={applicationKeys}/>
                                        </Tab>
                                        <Tab icon="storage" label={this.props.context.msg.t('projects')}>
                                            <ProjectsTab projects={projects}/>
                                        </Tab>
                                    </TabSet>
                                </div>

                                <DefaultDialogActions contentFullWidth={true}
                                                      disabled={!!isSomeOverload}
                                                      {...this.props}
                                />

                            </Form>
                        )
                    }}
                </Formik>
            </Dialog>
        )
    }
}

export default ContextEnhancer(
    connect()(MyAccountsDialog)
);