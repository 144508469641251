import styles from './AccProposalsTab.module.css';

import React, { useEffect, useMemo, useState } from 'react';
import useBimContext from 'components/hooks/useBimContext';
import UiMsg from 'components/ui/UiMsg';
import Api from 'components/Api';
import Utils from 'components/Utils';
import FilterDropdown from 'components/bng/pages/common/filter/FilterDropdown';
import FilterOpts from 'components/bng/pages/common/filter/FilterOpts';
import bngYup from 'components/bng/form/yup/BngYup';
import SimpleAccTableTab from 'components/ui/accounts/tabs/SimpleAccTableTab';
import BngDropdown from 'components/bng/ui/BngDropdown';
import { MODALS } from 'components/ui/redux/Actions';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import ProposalsInflatorsDialog from 'components/ui/accounts/tabs/ProposalsInflatorsDialog';
import { proposalUtils } from 'components/service/bng/AccountApi';
import ProposalActivationInfoDialog from 'components/ui/accounts/tabs/ProposalActivationInfoDialog';

export const PROPOSAL_STATUS = {
  ENABLED: 'accepted',
  WAITING: 'pending',
  REJECTED: 'rejected',
  DISABLED: 'disabled',
  CANCELLED: 'cancelled',
};

const buildProposalsColumns = ({
  account,
  context,
  dispatch,
  resolveProposal,
  isMasterOfCurrentAccount,
  isBimAccount,
  resendProposalEmail,
}) => {
  return [
    {
      key: 'id',
      label: context.msg.t('proposal.id'),
      sortable: false,
      render: (row) => {
        return (
          <div>
            <span>{row.proposalId}</span>
          </div>
        );
      },
    },
    {
      key: 'quantity',
      label: context.msg.t('quantity'),
      render: (row) => {
        return (
          <div>
            <span>{row.quantity}</span>
          </div>
        );
      },
    },
    {
      key: 'name',
      label: context.msg.t('name'),
      render: (row) => {
        const translatedProps = proposalUtils.translationsForProposalPricing(row);
        if (!translatedProps?.name) {
          return '';
        }

        return (
          <div>
            <span>{translatedProps.name}</span>
          </div>
        );
      },
    },
    {
      key: 'acceptDate',
      label: context.msg.t('accept.date'),
      render: (row) => {
        const date = row.acceptDate ? Utils.Date.formatDate(row.acceptDate) : '**/**/****';
        return (
          <div>
            <span>{date}</span>
          </div>
        );
      },
    },
    {
      key: 'acceptedBy',
      label: context.msg.t('accepted.by'),
      render: (row) => {
        const label = proposalUtils.activationUserFromPricing(row);
        return (
          <div>
            <span>{context.msg.t(label, true)}</span>
          </div>
        );
      },
    },
    {
      key: 'status',
      label: context.msg.t('status'),
      render: (row) => {
        const status =
          row.proposalStatus === 'ACCEPTED' ? PROPOSAL_STATUS[row.status] : PROPOSAL_STATUS[row.proposalStatus];
        return (
          <div>
            <span className={styles[status]}>{context.msg.t(status)}</span>
          </div>
        );
      },
    },
    {
      key: 'action',
      label: context.msg.t('action'),
      render: (row) => {
        return (
          <div className={styles.actionRow}>
            <BngDropdown
              options={[
                {
                  label: context.msg.t('proposal.details'),
                  icon: 'privacy_tip',
                  onClick: () => {
                    const proposalProps = row.proposalProps;

                    dispatch(
                      MODALS.open(ProposalActivationInfoDialog, {
                        activationProps: proposalProps.activationProps,
                        requestProps: proposalProps.requestProps,
                        removalProps: proposalProps.removalProps,
                        proposalPricing: row,
                        account: account,
                      }),
                    );
                  },
                },
                {
                  visible: row.proposalStatus === 'ACCEPTED',
                  label: context.msg.t('see.inflators'),
                  icon: 'trending_up',
                  onClick: () => {
                    const type = proposalUtils.getProposalPricingType(row);
                    dispatch(
                      MODALS.open(ProposalsInflatorsDialog, {
                        accountId: account.id,
                        proposalPricingId: row.id,
                        proposalPricingType: type,
                        proposalAcceptDate: row.acceptDate,
                      }),
                    );
                  },
                },
                {
                  visible: row.proposalStatus === 'WAITING',
                  label: context.msg.t('proposal.accept'),
                  icon: 'check_circle',
                  onClick: async () => {
                    await resolveProposal(row.proposalId, proposalUtils.ACTIVATION_TYPES.ACCEPTED);
                  },
                },
                {
                  visible: row.proposalStatus === 'WAITING' && isMasterOfCurrentAccount,
                  label: context.msg.t('proposal.reject'),
                  icon: 'cancel',
                  onClick: async () => {
                    await resolveProposal(row.proposalId, proposalUtils.ACTIVATION_TYPES.REJECTED);
                  },
                },
                {
                  visible: row.proposalStatus === 'WAITING' && isBimAccount,
                  label: context.msg.t('cancel'),
                  icon: 'block',
                  onClick: async () => {
                    await resolveProposal(row.proposalId, proposalUtils.ACTIVATION_TYPES.CANCELLED);
                  },
                },
                {
                  visible: row.proposalStatus === 'WAITING' && isBimAccount,
                  label: context.msg.t('resend.proposal.email'),
                  icon: 'forward_to_inbox',
                  onClick: async () => {
                    await resendProposalEmail(row.proposalId);
                  },
                },
              ]}
            />
          </div>
        );
      },
    },
  ];
};

const ProposalFilterDropdownSchema = bngYup((yup) => {
  return yup.object().shape({
    acceptedBy: yup.string().nullable().default(''),
    status: yup.array(yup.string()).default([]),
  });
});

const ProposalsFilterDropdown = ({ rows = [], onChange = _.noop, initialValues = [], filters = {} }) => {
  const context = useBimContext();

  const statusOpts = FilterOpts({
    options: Object.values(PROPOSAL_STATUS),
    hasIcon: true,
    customIcon: (obj) => <div className={`${styles.statusBall} ${styles[`background-${obj}`]}`} />,
  });
  const acceptedByOpts = FilterOpts({
    options: _.uniq(
      rows
        .map((pricing) => {
          return proposalUtils.activationUserFromPricing(pricing);
        })
        .filter((activationUser) => {
          return !!activationUser;
        }),
    ),
  });

  const filterFields = [
    {
      name: 'acceptedBy',
      label: context.msg.t('accepted.by'),
      options: acceptedByOpts,
    },
    {
      name: 'status',
      options: statusOpts,
      multiple: true,
    },
  ];

  return (
    <FilterDropdown
      fields={filterFields}
      dropdownSchema={ProposalFilterDropdownSchema}
      initialFormValues={initialValues}
      onChange={onChange}
    />
  );
};

const filterPricings = (filters, pricings) => {
  const {
    search,
    filterButton: { acceptedBy, status },
  } = filters;

  if (search) {
    pricings = pricings.filter((pricing) => {
      const translatedProps = proposalUtils.translationsForProposalPricing(pricing);
      return (
        translatedProps.name.toLowerCase().includes(search.toLowerCase()) ||
        pricing.proposalId.toString().includes(search)
      );
    });
  }

  if (acceptedBy) {
    pricings = pricings.filter((pricing) => proposalUtils.activationUserFromPricing(pricing) === acceptedBy);
  }

  if (status && status.length != 0) {
    pricings = pricings.filter((pricing) =>
      pricing.proposalStatus === 'ACCEPTED'
        ? status.includes(PROPOSAL_STATUS[pricing.status])
        : status.includes(PROPOSAL_STATUS[pricing.proposalStatus]),
    );
  }

  return pricings;
};

export default function AccProposalsTab({ account, isMasterOfCurrentAccount }) {
  const context = useBimContext();
  const dispatch = useReduxDispatch();
  const [loading, setLoading] = useState(false);
  const [pricings, setPricings] = useState([]);

  const resolveProposal = async (proposalId, status) => {
    setLoading(true);
    try {
      await Api.Account.resolveProposal(account.id, proposalId, status, context.project.id);
      UiMsg.ok(
        context.msg.t(`proposal.${status === proposalUtils.ACTIVATION_TYPES.ACCEPTED ? 'accept' : 'reject'}.success`),
      );
      await fetchPricings();
    } catch (e) {
      console.error('Error on function acceptProposal()', e);
      UiMsg.error(context.msg.t('accept.request.error'));
    } finally {
      setLoading(false);
    }
  };

  const resendProposalEmail = async (proposalId) => {
    try {
      await Api.Account.resendProposalEmail(proposalId, context.project.id);
      UiMsg.ok(context.msg.t('resend.proposal.email.success'));
    } catch (e) {
      console.error('Error on function resendProposalEmail()', e);
      UiMsg.error(context.msg.t('resend.proposal.email.error'));
    }
  };

  const cols = useMemo(
    () =>
      buildProposalsColumns({
        account,
        context,
        dispatch,
        resolveProposal,
        isMasterOfCurrentAccount,
        isBimAccount: Utils.Users.isConsultant(context.user),
        resendProposalEmail,
      }),
    [account, pricings],
  );

  const fetchPricings = async () => {
    setLoading(true);
    try {
      const fetchedPricings = await Api.Account.fetchPricings(account.id);
      const pricings = fetchedPricings.additionalPricings
        .concat(fetchedPricings.servicePricings)
        .concat(fetchedPricings.planPricings);
      setPricings(pricings.filter((p) => p.status !== 'DISABLED'));
    } catch (e) {
      console.error('Error on function fetchProposals()', e);
      UiMsg.ajaxError(context.msg.t('fetch.proposals.error'), e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPricings();
  }, [account]);

  return (
    <SimpleAccTableTab
      className="AccProposalsTab"
      cols={cols}
      rows={pricings}
      filterFunc={filterPricings}
      initialFilters={{
        search: '',
        filterButton: { status: [PROPOSAL_STATUS.ENABLED, PROPOSAL_STATUS.WAITING] },
      }}
      filterComponent={ProposalsFilterDropdown}
      onReload={fetchPricings}
      emptyAlertMsg={context.msg.t('no.proposals')}
      loading={loading}
      setLoading={setLoading}
      headerButtonDisabled={true}
      headerButtonIcon={'open_in_new'}
      headerButtonLabel={context.msg.t('see.contract')}
    />
  );
}
