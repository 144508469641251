import styles from './LabsVotingAdminDialog.module.css';

import React, { useEffect, useMemo, useState } from 'react';
import { Field, Formik } from 'formik';

import Dialog from 'components/ui/Dialog';
import UiMsg from 'components/ui/UiMsg';
import Api from 'components/Api';
import BngButton, { Size, Variant } from 'components/bng/ui/BngButton';
import useBimContext from 'components/hooks/useBimContext';
import bngYup from 'components/bng/form/yup/BngYup';
import BngField from 'components/bng/form/BngField';
import BngForm from 'components/bng/form/BngForm';
import BngSelect from 'components/bng/form/BngSelect';
import BngDate from 'components/bng/form/BngDate';
import BngInputRichText from 'components/bng/form/BngInputRichText';
import Utils from 'components/Utils';
import BngTable from 'components/bng/ui/BngTable';
import BngTag from 'components/bng/ui/BngTag';
import BngDropdown from 'components/bng/ui/BngDropdown';
import OpConfirmation from 'components/ui/OpConfirmation';
import BngImageUpload from 'components/bng/form/BngImageUpload';
import { BngSelectSearch } from 'components/bng/form/BngSelectSearch';
import useFetchData from 'components/hooks/useFetchData';
import useDebouncedValue from 'components/hooks/useDebouncedValue';

const FeatureProposalSchema = bngYup((yup) => {
  return yup.object().shape({
    title: yup.string().required().ensure().default(''),
    category: yup.string().required().ensure().default(''),
    status: yup.string().required().ensure().default(''),
    summary: yup.string().required().ensure().default(''),
    description: yup.string().ensure().default(''),
    editableDate: yup.string().required().default(Utils.Date.htmlFormatDate(new Date())),
    author: yup.string().required().ensure().default('BIMachine'),
    imagePath: yup.mixed().required(),
  });
});

function FeatureProposalForm({ categoryOpts, statusOpts, feature = {}, setLoading = _.noop, onChange = _.noop }) {
  const { msg } = useBimContext();

  const [userSearch, setUserSearch] = useDebouncedValue('', 200);

  const $users = useFetchData(async () => {
    const users = await Api.Admin.findUsers({ search: userSearch ?? '', initial: 0, maxResults: 50 });
    return users.content.map((user) => ({ value: user.id, label: user.displayName }));
  }, [userSearch]);

  const initialFormValues = useMemo(() => {
    return _.merge(FeatureProposalSchema.default(), feature);
  }, [feature]);

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      values = _.cloneDeep(values);

      if (!_.isString(values.imagePath)) {
        const links = await Api.Upload.uploadAndResize(values.imagePath, 167, 94);
        values.imagePath = links[0].path;
      }

      await Api.FeatureProposal.save({
        ...values,
        editableDate: Utils.Date.toMoment(values.editableDate).toDate(),
      });
      UiMsg.ok(msg.t('save_success', [`${msg.t('feature')} ${values.title}`]));
      onChange();
    } catch (e) {
      console.error('Error while trying to save a voting feature', e);
      UiMsg.ajaxError(null, e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={FeatureProposalSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <BngForm className="FeatureProposalForm">
        <div className={styles.fieldsContainer}>
          <div className={`${styles.groupFields}`}>
            <Field name="title" rootClassName={`${styles.fieldMdSize}`} label={msg.t('title')} component={BngField} />
            <Field
              name="author"
              rootClassName={`${styles.fieldMdSize}`}
              label={msg.t('presentation.createby')}
              component={BngField}
            />
          </div>
          <div className={`${styles.groupFields}`}>
            <Field
              name="category"
              rootClassName={`${styles.fieldMdSize}`}
              label={msg.t('webservice.tomticket.categoria')}
              component={BngField}
              inputComponent={BngSelect}
              options={categoryOpts}
            />
            <Field
              name="status"
              rootClassName={`${styles.fieldMdSize}`}
              label={msg.t('inMemory_status')}
              component={BngField}
              inputComponent={BngSelect}
              options={statusOpts}
            />
          </div>
          <Field name="summary" label={msg.t('publiclabs.voting.summary')} component={BngField} />
          <Field
            name="description"
            label={msg.t('description')}
            component={BngField}
            inputComponent={BngInputRichText}
          />
          <div className={`${styles.groupFields}`}>
            <Field
              name="editableDate"
              label={msg.t('publiclabs.voting.editableDate')}
              component={BngField}
              inputComponent={BngDate}
            />
            <Field
              name="userId"
              rootClassName={`${styles.fieldMdSize}`}
              label={msg.t('User')}
              component={BngField}
              inputComponent={BngSelectSearch}
              options={$users.data ?? []}
              onSearch={({ search }) => {
                const newValue = search ?? '';
                if (newValue !== userSearch) {
                  setUserSearch(newValue);
                }
              }}
            />
          </div>
          <div className={`${styles.groupFields}`}>
            <Field
              name={'imagePath'}
              label={msg.t('background.image')}
              component={BngField}
              inputComponent={BngImageUpload}
            />
          </div>
        </div>
        <div className={`${styles.flexCtn} mt-2`}>
          <BngButton size={Size.lg} btnType={'submit'}>
            {msg.t('save_button')}
          </BngButton>
        </div>
      </BngForm>
    </Formik>
  );
}

function FeatureTable({ featureProposals, categoryOpts, statusOpts, onDelete = _.noop, onEdit = _.noop }) {
  const { msg } = useBimContext();

  const tableColumns = useMemo(() => {
    return [
      {
        key: 'title',
        label: msg.t('title'),
        render: (row) => <span>{row.title}</span>,
      },
      {
        key: 'category',
        label: msg.t('categories'),
        render: (row) => {
          const category = categoryOpts.find((c) => row.category === c.value);
          return <BngTag description={category?.label} style={{ backgroundColor: category?.color }} />;
        },
      },
      {
        key: 'status',
        label: msg.t('status'),
        render: (row) => {
          const status = statusOpts.find((a) => row.status === a.value);
          return <BngTag className={styles[status?.value]} description={status?.label} />;
        },
      },
      {
        key: 'action',
        label: msg.t('action'),
        render: (row) => {
          return (
            <div>
              <BngDropdown
                popperOpts={{ placement: 'bottom-end' }}
                popperClassName={styles.tableActionPopper}
                overDialog
                options={[
                  {
                    label: msg.t('edit_button'),
                    icon: 'edit',
                    onClick: () => onEdit(row),
                  },
                  {
                    label: msg.t('delete_button'),
                    icon: 'close',
                    onClick: () =>
                      OpConfirmation({
                        title: msg.t('attention'),
                        html: msg.t('publiclabs.voting.admin.delete'),
                        onConfirm: () => onDelete(row),
                        msg: msg,
                        confirmText: msg.t('definitive.delete'),
                        reverseButtons: true,
                      }),
                  },
                ]}
              />
            </div>
          );
        },
      },
    ];
  }, [categoryOpts, statusOpts]);

  return (
    <div className={`FeatureTable ${styles.adminTableContainer}`}>
      <BngTable rows={featureProposals} cols={tableColumns} />
    </div>
  );
}

export default function LabsVotingAdminDialog({ closeModal, categoryOpts = [], statusOpts = [] }) {
  const { msg, user } = useBimContext();

  const [featureProposals, setFeatureProposals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState();

  const fetchFeatureProposals = async () => {
    try {
      setLoading(true);
      let featureProposals = await Api.FeatureProposal.findAll();
      featureProposals = featureProposals.sort(
        (a, b) =>
          Number(a.status === 'FINISHED' || a.status === 'REJECTED') -
            Number(b.status === 'FINISHED' || b.status === 'REJECTED') ||
          Utils.Strings.compareIgnoreCase(a.title, b.title)
      );

      setFeatureProposals(featureProposals);
    } catch (e) {
      console.error('Error while trying to fetch feature proposals', e);
      UiMsg.ajaxError(null, e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFeatureProposals();
  }, []);

  const deleteFeatureProposal = async (row) => {
    try {
      setLoading(true);
      await Api.FeatureProposal.delete(row.id);
      fetchFeatureProposals();
      UiMsg.ok(msg.t('remove_success', [`${msg.t('feature')} ${row.title}`]));
    } catch (e) {
      console.error('Error while trying to delete a voting feature', e);
      UiMsg.ajaxError(null, e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      className={`LabsVotingAdminDialog ${styles.dialog}`}
      newDialogLayout
      onClose={closeModal}
      loading={loading}
      title={msg.t('new.analysis.help.title', user.displayName)}
    >
      <div className={`${styles.dialogWrapper}`}>
        <div className={`${styles.header}`}>
          {!selectedFeature ? (
            <BngButton
              className={`${styles.addButton}`}
              variant={Variant.outline}
              icon={'add'}
              onClick={() => setSelectedFeature({})}
            >
              {msg.t('add')}
            </BngButton>
          ) : (
            <BngButton
              className={`${styles.addButton}`}
              variant={Variant.outline}
              icon={'arrow_back'}
              onClick={() => setSelectedFeature(null)}
            >
              {msg.t('back_button')}
            </BngButton>
          )}
        </div>

        {!selectedFeature ? (
          <FeatureTable
            featureProposals={featureProposals}
            statusOpts={statusOpts}
            categoryOpts={categoryOpts}
            onDelete={(row) => deleteFeatureProposal(row)}
            onEdit={(row) => setSelectedFeature(row)}
          />
        ) : (
          <div className={`${styles.body}`}>
            <FeatureProposalForm
              feature={selectedFeature}
              categoryOpts={categoryOpts}
              statusOpts={statusOpts}
              setLoading={setLoading}
              onChange={() => {
                fetchFeatureProposals();
                setSelectedFeature(null);
              }}
            />
          </div>
        )}
      </div>
    </Dialog>
  );
}
