import styles from './ProjectManagement.module.css';

import React, { useEffect, useMemo, useState } from 'react';
import { Formik } from 'formik';
import { useSearchParams } from 'react-router-dom';

import useBimContext from 'components/hooks/useBimContext';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import PageLayout from 'components/bng/pages/common/layout/PageLayout';
import UiBlocker from 'components/bng/ui/UiBlocker';
import UiMsg from 'components/ui/UiMsg';
import BngDropdown from 'components/bng/ui/BngDropdown';
import BngButton from 'components/bng/ui/BngButton';
import BngIconButton from 'components/bng/ui/BngIconButton';
import Api from 'components/Api';
import ProjectGeneralTab from 'components/bng/pages/project/management/tabs/ProjectGeneralTab';
import ProjectStyleTab from 'components/bng/pages/project/management/tabs/ProjectStyleTab';
import ProjectWhiteLabelTab from 'components/bng/pages/project/management/tabs/ProjectWhiteLabelTab';
import ProjectDomainTab from 'components/bng/pages/project/management/tabs/ProjectDomainTab';
import BngForm from 'components/bng/form/BngForm';
import bngYup from 'components/bng/form/yup/BngYup';
import ProjectMergeDialog from 'components/ui/project/ProjectMergeDialog';
import ProjectUpdateAppsDialog from 'components/bng/project/updateApps/ProjectUpdateAppsDialog';
import { MODALS } from 'components/ui/redux/Actions';
import useTranslation from 'components/hooks/useTranslation';
import OpTextConfirmation from 'components/ui/OpTextConfirmation';
import OpConfirmation from 'components/ui/OpConfirmation';
import ProjectReplicationDialog from 'components/bng/project/ProjectReplicationDialog';
import NavPanel from 'components/ui/accounts/NavPanel';
import { checkAddonEnabled } from 'components/bng/accounts/addons/AddonDisabledDialog';
import useId from 'components/hooks/useId';
import ProjectConfigurationTab from 'components/bng/pages/project/management/tabs/ProjectConfigurationTab';
import useFetchData from 'components/hooks/useFetchData';
import AccessTimeRestrictionTab from 'components/bng/pages/project/management/tabs/AccessTimeRestrictionTab';
import { calculateAccountConsumption } from 'components/ui/accounts/tabs/AccFinanceTab';

const buildTabGroups = ({ addons, isConsultant }) => {
  const whiteLabel = !!addons.find((a) => a === 'VISUAL_IDENTITY');
  const domainCustomization = !!addons.find((a) => a === 'DOMAIN_NAME');
  const accessTimeRestriction = !!addons.find((a) => a === 'ACCESS_TIME_RESTRICTION');

  const tabGroups = [
    {
      key: 'general',
      label: 'projectManagementTab.general',
      component: ProjectGeneralTab,
      icon: 'settings',
    },
    {
      key: 'style',
      label: 'projectManagementTab.style',
      component: ProjectStyleTab,
      icon: 'style',
    },
    {
      key: 'whiteLabel',
      label: 'addon.visual.identity.name',
      component: whiteLabel ? ProjectWhiteLabelTab : undefined,
      icon: 'palette',
      onSelect: ({ defaultAction }) => checkAddonEnabled('VISUAL_IDENTITY', true, defaultAction),
    },
    {
      key: 'domainCustomization',
      label: 'projectManagementTab.domainCustomization',
      component: domainCustomization ? ProjectDomainTab : undefined,
      icon: 'public',
      onSelect: ({ defaultAction }) => checkAddonEnabled('DOMAIN_NAME', true, defaultAction),
    },
    {
      key: 'accessTimeRestriction',
      label: 'addon.access.time.restriction.name',
      component: accessTimeRestriction ? AccessTimeRestrictionTab : undefined,
      icon: 'lock_clock',
      enabled: true,
      onSelect: ({ defaultAction }) => checkAddonEnabled('ACCESS_TIME_RESTRICTION', true, defaultAction),
    },
  ];

  if (isConsultant) {
    tabGroups.push({
      key: 'advancedConfigurations',
      label: 'project.config.advanced.config',
      component: ProjectConfigurationTab,
      icon: 'build',
    });
  }

  return tabGroups;
};

const ProjectManagementGeneralSchema = bngYup((yup) => {
  return yup.object({
    //General tab validator
    name: yup.string().default(''),
    displayName: yup.string().trim().required().default(''),
    projectUrl: yup.string().default(''),
    description: yup.string().default(''),
    language: yup.string().default('pt_BR'),
    supportEnabled: yup.boolean().default(false),
    mobileEnabled: yup.boolean().default(false),
    mobileEnabledForObjects: yup.boolean().default(false),
    supportForAdmins: yup.boolean().default(false),
    allowExcel: yup.boolean().default(false),
    projectType: yup.string().default('Demo'),
  });
});

const ProjectManagementStyleSchema = bngYup((yup) => {
  return yup.object({
    //Style tab validator
    colorScheme: yup.string().default(''),
    tableTheme: yup.string().default(''),
    selectedPaletteColors: yup.object().shape({
      label: yup.string(),
      pallete: yup.array().of(yup.string()),
      value: yup.string(),
    }),

    rowSeparator: yup.string().default('none'),
    tableRowLineSize: yup.string().default('small'),
    tableFontSize: yup.number().positive().integer().min(1).max(45).default(12),
    exportPageSize: yup.string().default('A4'),
    exportPageOrientation: yup.string().default('portrait'),
    showHeader: yup.boolean().default(false),
    showBackground: yup.boolean().default(false),
    showFooter: yup.boolean().default(false),
    showPagination: yup.boolean().default(false),
    titleSize: yup.number().positive().integer().min(1).max(45).default(12),
    descriptionSize: yup.number().positive().integer().min(1).max(45).default(12),
    filterSize: yup.number().positive().integer().min(1).max(45).default(12),

    dashLayoutTheme: yup.string().default('WHITE'),
    dashLayoutHighlightColor: yup.string().default('#528de3'),
    dashLayoutBackgroundImage: yup.string().nullable().default(''),
    dashLayoutDivisions: yup.number().default(24),
    dashLayoutTransparency: yup.number().default(0),
    dashLayoutItemsMargin: yup.boolean().default(true),
    dashLayoutMargin: yup.number().min(0).max(10).default(6),
    dashLayoutContainerMargin: yup.boolean().default(true),
    dashLayoutContainerMarginValue: yup.number().min(0).max(10).default(6),
    dashLayoutItemsShadow: yup.boolean().default(true),
    dashLayoutBorderRadius: yup.boolean().default(true),
  });
});

const ProjectManagementWhiteLabelSchema = bngYup((yup) => {
  return yup.object({
    //White-Label tab validator
    favIcon: yup.mixed(),
    faviconEnabled: yup.boolean().default(false),
    headerLogo: yup.mixed(),
    headerLogoEnabled: yup.boolean().default(false),
    toolbarLogo: yup.mixed(),
    toolbarLogoEnabled: yup.boolean().default(false),
    loginBackgroundImage: yup.mixed(),
    loginBackgroundImageEnabled: yup.boolean().default(false),
    loginBackgroundImageSize: yup.string().default('contain'),
    loginBoxAlign: yup.string().default('center'),
    loginTheme: yup.string().default('light_mode'),
  });
});

const ProjectManagementDomainSchema = bngYup((yup) => {
  return yup.object({
    // DomainCustomization tab validator
    customUrl: yup.string().nullable(),
  });
});

const schemas = {
  domain: ProjectManagementDomainSchema,
  whiteLabel: ProjectManagementWhiteLabelSchema,
  general: ProjectManagementGeneralSchema,
  style: ProjectManagementStyleSchema,
};

const FormSchema = bngYup((yup) => {
  return Object.values(schemas).reduce((mergedSchemas, schema) => mergedSchemas.concat(schema), yup.object());
});

async function renderDeleteProjectConfirmDialog(project, msg) {
  try {
    const { installedOn } = await Api.ProjectApp.findInstalls(project.id);
    if (_.isEmpty(installedOn)) {
      OpTextConfirmation({
        msg,
        title: msg.t('attention'),
        html: msg.t('project.removal.confirmation.message', project.name),
        nameToDelete: project.name,
        onConfirm: async () => {
          try {
            await Api.Project.scheduleProjectRemoval(project.id);
            UiMsg.ok(msg.t('project.removal.scheduled.message', [project.name]));
          } catch (e) {
            console.error(e);
            UiMsg.error('project.scheduled.for.removal.error', e);
          }
        },
        validationMessage: msg.t('project.removal.confirmation.error'),
      });
    } else {
      OpConfirmation({
        title: msg.t('attention'),
        msg: msg,
        showCancelButton: false,
        html: `<div style='margin-bottom: 10px'>
                  <span>${msg.t('project.has.child.warning')}</span>
                </div>
                <div style='max-height: 300px; overflow-y: scroll; display: flex; text-align: start; justify-content: center'>
                    <ul>
                        ${installedOn.map((p) => `<li style='margin-bottom: 5px'>${p.app.caption}</li>`).join('')}
                    </ul>
                </div>`,
        confirmText: msg.t('ok.understood'),
      });
    }
  } catch (e) {
    console.error('Error on renderDeleteProjectConfirmDialog()', e);
    UiMsg.ajaxError(null, e);
  }
}

function FooterComponent() {
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
      <BngButton btnType={'submit'}>{t('save')}</BngButton>
    </div>
  );
}

function FloatActionButtonDropdown({ selectedProject, findProjectsForManagement }) {
  const context = useBimContext();
  const { i18n, t } = useTranslation();
  const dispatch = useReduxDispatch();
  const componentId = useId();

  const [open, setOpen] = useState(false);
  const boundaryElement = useMemo(() => document.getElementById('page-content'), []);

  const disabled = !selectedProject?.project?.enabled;
  const isMaster = selectedProject?.isMaster;
  const canImport = context.permissions.canImportProjects();

  const { data: showUpdateButton = false } = useFetchData(async () => {
    if (!selectedProject || (!isMaster && !canImport)) {
      return false;
    }
    try {
      const projectData = await Api.ProjectApp.findInstalls(selectedProject.project.id);
      return !_.isEmpty(projectData.installs) || !_.isEmpty(projectData.installedOn);
    } catch (e) {
      console.error('Error while trying to find project installs', e);
      UiMsg.ajaxError(null, e);
    }
    return false;
  }, [selectedProject, isMaster, canImport]);

  if (!isMaster && !canImport) {
    return null;
  }

  const toggleProjectState = async () => {
    let messageKey = disabled ? 'project.enabled' : 'project.disabled';
    if (context.accountEnabledForBilling && selectedProject.project.projectType === 'Production' && disabled) {
      const accountInfo = await Api.Account.fetchBillingAccountInfo(selectedProject.account.id);
      const metrics = calculateAccountConsumption(accountInfo, selectedProject);
      if (metrics.limits.userExceeds || metrics.limits.structuresExceeds) {
        UiMsg.error(t('activate.project.metrics.limit.error'));
        return;
      }
    }
    try {
      await Api.Project.toggleEnabled(selectedProject.project.id);
      UiMsg.ok(t(`${messageKey}.success`, selectedProject.project.displayName));
      findProjectsForManagement();
    } catch (e) {
      console.error('Error on toggleProjectState()', e);
      UiMsg.ajaxError(t(`${messageKey}.error`, selectedProject.project.displayName), e);
    }
  };

  return (
    <BngDropdown
      className={`FloatActionButton`}
      popperClassName={`FloatActionButtonDropdown`}
      customButton={({ openDropdown }) => (
        <BngIconButton icon={'add'} className={`info ${open ? 'Open' : ''}`} onClick={openDropdown} />
      )}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      boundariesElement={boundaryElement}
      popperOpts={{
        placement: 'bottom-start',
      }}
      customOptions={({ closeDropdown }) => (
        <div className={`${styles.FloatActionButtonDropdown}`}>
          <div>
            <BngIconButton
              id={`${componentId}-replication-manager-button`}
              icon={'copy_all'}
              style={{ backgroundColor: '#00A355' }}
              onClick={() =>
                dispatch(
                  MODALS.open(ProjectReplicationDialog, {
                    project: selectedProject.project,
                  })
                )
              }
            />
            <label htmlFor={`${componentId}-replication-manager-button`}>{t('project.replicate')}</label>
          </div>
          <div>
            <BngIconButton
              id={`${componentId}-project-import-button`}
              icon={'input'}
              style={{ backgroundColor: '#00A355' }}
              onClick={() => dispatch(MODALS.open(ProjectMergeDialog, { projectId: selectedProject.project.id }))}
            />
            <label htmlFor={`${componentId}-project-import-button`}>{t('project.import')}</label>
          </div>
          {showUpdateButton && (
            <div>
              <BngIconButton
                id={`${componentId}-project-update-button`}
                icon={'sync'}
                style={{ backgroundColor: '#00A355' }}
                onClick={() =>
                  dispatch(MODALS.open(ProjectUpdateAppsDialog, { projectId: selectedProject.project.id }))
                }
              />
              <label htmlFor={`${componentId}-project-update-button`}>{t('project.update')}</label>
            </div>
          )}

          {isMaster && (
            <>
              <div>
                <BngIconButton
                  id={`${componentId}-project-activate-desactivate-button`}
                  icon={'power_settings_new'}
                  style={{ backgroundColor: '#EEA42C' }}
                  onClick={toggleProjectState}
                />
                <label htmlFor={`${componentId}-project-activate-desactivate-button`}>
                  {t(disabled ? 'project.deactivate' : 'project.activate')}
                </label>
              </div>
              <div>
                <BngIconButton
                  id={`${componentId}-project-delete-button`}
                  icon={'delete_forever'}
                  style={{ backgroundColor: '#E23D3D' }}
                  onClick={() => renderDeleteProjectConfirmDialog(selectedProject.project, i18n)}
                />
                <label htmlFor={`${componentId}-project-delete-button`}>{t('project.delete')}</label>
              </div>
            </>
          )}
        </div>
      )}
    />
  );
}

export default function ProjectManagement({ className = '' }) {
  const context = useBimContext();
  const dispatch = useReduxDispatch();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [projectOpts, setProjectOpts] = useState([]);
  const [selectedProject, setSelectedProject] = useState();

  const currentTabKey = searchParams.get('currentTab') || 'general';

  const changeProject = async (projectId) => {
    const project = await Api.ProjectManagement.findOne(projectId);
    setSelectedProject(project);
  };

  const findProjectsForManagement = async () => {
    setLoading(true);
    try {
      const projects = await Api.ProjectManagement.findAll();
      const projectOpts = projects.map((project) => ({
        value: project.id,
        label: project.displayName,
      }));
      setProjectOpts(projectOpts);

      const projectId = selectedProject?.project.id ?? context.project.id ?? projects[0].id;
      await changeProject(projectId);
    } catch (e) {
      console.error('Error on findProjectsForManagement() during mount', e);
      UiMsg.ajaxError(null, e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    findProjectsForManagement();
  }, []);

  const initialValues = useMemo(() => {
    const temp = FormSchema.default();

    if (!selectedProject || !selectedProject.project) {
      return temp;
    }
    const {
      project,
      options,
      theme: { themeDashboard, ...theme },
    } = selectedProject;

    temp.id = project.id;
    temp.displayName = project.displayName;
    temp.name = project.name;
    temp.projectUrl = project.projectUrl;
    temp.description = project.description;
    temp.language = project.language;
    temp.customUrl = project.customUrl;
    temp.projectType = project.projectType;

    temp.supportEnabled = options.supportEnabled;
    temp.mobileEnabled = options.mobileEnabled;
    temp.mobileEnabledForObjects = options.mobileEnabledForObjects;
    temp.supportForAdmins = options.supportForAdmins;
    temp.allowExcel = options.allowExcel;
    temp.mdxRemoveUnusedMembers = options.mdxRemoveUnusedMembers;
    temp.timeDimensionsLegacyMode = options.timeDimensionsLegacyMode;

    temp.colorScheme = theme.colorScheme;
    temp.tableTheme = theme.tableTheme;
    temp.selectedPaletteColors = theme.customColorScheme;
    temp.rowSeparator = theme.rowSeparator;
    temp.tableRowLineSize = theme.tableRowLineSize;
    temp.tableFontSize = theme.tableFontSize;
    temp.exportPageSize = theme.themePrinter.exportPageSize;
    temp.exportPageOrientation = theme.themePrinter.exportPageOrientation;
    temp.showHeader = theme.themePrinter.showHeader;
    temp.showBackground = theme.themePrinter.showBackground;
    temp.showFooter = theme.themePrinter.showFooter;
    temp.showPagination = theme.themePrinter.showPagination;
    temp.titleSize = theme.themePrinter.header.titleSize;
    temp.descriptionSize = theme.themePrinter.header.descriptionSize;
    temp.filterSize = theme.themePrinter.header.filterSize;

    const processImage = (img) => {
      if (img === null) {
        return {
          id: null,
          name: null,
          value: null,
          relativeLink: null,
          type: null,
        };
      }
      return {
        id: img.id,
        name: img.name,
        value: `${Api.baseUrl()}/${img.relativeLink}`,
        relativeLink: `${Api.baseUrl()}/${img.relativeLink}`,
        type: img.type,
      };
    };

    temp.favIcon = processImage(theme.favIcon);
    temp.faviconEnabled = theme.enableFavicon;
    temp.headerLogo = processImage(theme.headerLogo);
    temp.headerLogoEnabled = theme.enableHeaderLogo;
    temp.toolbarLogo = processImage(theme.toolbarLogo);
    temp.toolbarLogoEnabled = theme.enableToolbarLogo;
    temp.loginBackgroundImage = processImage(theme.loginBackgroundImage);
    temp.loginBackgroundImageEnabled = theme.enableLoginBackgroundImage;
    temp.loginBackgroundImageSize = theme.sizes.filter((ts) => ts.name === 'login-background-image-size')[0].value;
    temp.loginBoxAlign = theme.properties.filter((tp) => tp.name === 'login-box-align')[0].value;
    temp.loginTheme = theme.loginTheme ?? 'LIGHT';

    temp.dashLayoutTheme = themeDashboard.backgroundTheme;
    temp.dashLayoutHighlightColor = themeDashboard.highlightBoxColor;
    temp.dashLayoutBackgroundImage = themeDashboard.backgroundImage;
    temp.dashLayoutDivisions = themeDashboard.divisions;
    temp.dashLayoutTransparency = themeDashboard.transparency;
    temp.dashLayoutItemsMargin = themeDashboard.hasMargin;
    temp.dashLayoutMargin = themeDashboard.margin;
    temp.dashLayoutContainerMargin = themeDashboard.hasContainerMargin;
    temp.dashLayoutContainerMarginValue = themeDashboard.containerMargin;
    temp.dashLayoutItemsShadow = themeDashboard.hasBoxShadow;
    temp.dashLayoutBorderRadius = themeDashboard.hasBorderRadius;

    return temp;
  }, [selectedProject]);

  const onSave = async (values) => {
    if (_.isEqual(values, initialValues)) {
      return;
    }

    setLoading(true);
    try {
      if (values.customUrl) {
        const { error, msgKey } = await Api.ProjectManagement.validateCustomUrl(values.id, values.customUrl);
        if (error) {
          UiMsg.warn(t(msgKey));
          return;
        }
      }

      const options = {
        allowExcel: values.allowExcel,
        mobileEnabled: values.mobileEnabled,
        mobileEnabledForObjects: values.mobileEnabledForObjects,
        supportEnabled: values.supportEnabled,
        supportForAdmins: values.supportForAdmins,
        mdxRemoveUnusedMembers: values.mdxRemoveUnusedMembers,
        timeDimensionsLegacyMode: values.timeDimensionsLegacyMode,
      };

      const project = {
        id: values.id,
        description: values.description,
        displayName: values.displayName,
        language: values.language,
        name: values.name,
        projectUrl: values.projectUrl,
        customUrl: values.customUrl,
        projectType: selectedProject.project.projectType,
        enabled: selectedProject.project.enabled,
      };

      const properties = selectedProject.theme.properties.slice().filter((p) => p.type !== 'Image');

      const updateThemeProp = (og, themeProp, name, temp) => {
        const idx = properties.findIndex((e) => e[`${name}`] === temp[`${name}`]);
        if (idx !== -1) {
          og.splice(idx, 1, temp);
        } else {
          og.push(temp);
        }
      };

      const loginBackgroundImageSize = {
        id: selectedProject.theme.sizes.find((e) => e.name === 'login-background-image-size')?.id || null,
        name: 'login-background-image-size',
        relativeLink: `projects/${values.name}/${values.loginBackgroundImageSize}`,
        type: 'Size',
        value: values.loginBackgroundImageSize,
      };

      const loginBoxAlign = {
        id: selectedProject.theme.properties.find((e) => e.name === 'login-box-align')?.id || null,
        name: 'login-box-align',
        relativeLink: `projects/${values.name}/${values.loginBoxAlign}`,
        type: 'TextAlign',
        value: values.loginBoxAlign,
      };

      updateThemeProp(properties, 'properties', 'name', loginBackgroundImageSize);
      updateThemeProp(properties, 'properties', 'name', loginBoxAlign);

      const handleImageUpload = async (newImage, savedImage) => {
        if (_.isEqual(savedImage, newImage)) {
          return null;
        }

        if (newImage?.value !== null) {
          const uploadedFile = (await Api.Upload.upload(newImage.file, { temp: true }))[0];

          if (uploadedFile && uploadedFile.path) {
            const temp = _.cloneDeep(newImage);
            temp.name = newImage.name;
            temp.value = uploadedFile.path;
            temp.relativeLink = uploadedFile.link;
            temp.type = 'Image';
            delete temp.file;
            return temp;
          } else {
            console.error('Error: Uploaded file is undefined or has no path');
            return null;
          }
        }
        return null;
      };

      const handleThemeDashboardUpload = async (value) => {
        if (value?.file) {
          const uploadResults = await Api.Upload.upload(value.file, { temp: true });
          return uploadResults[0].link;
        }

        return value;
      };

      const theme = {
        colorScheme: values.colorScheme,
        tableTheme: values.tableTheme,
        customColorScheme: {
          palette: values.selectedPaletteColors.palette,
          value: 'custom',
          label: 'CUSTOMIZED',
        },
        rowSeparator: values.rowSeparator,
        tableRowLineSize: values.tableRowLineSize,
        tableFontSize: values.tableFontSize,
        themePrinter: {
          ...selectedProject.theme.themePrinter,
          exportPageOrientation: values.exportPageOrientation,
          exportPageSize: values.exportPageSize,
          header: {
            titleSize: values.titleSize,
            descriptionSize: values.descriptionSize,
            filterSize: values.filterSize,
          },
          showBackground: values.showBackground,
          showFooter: values.showFooter,
          showHeader: values.showHeader,
          showPagination: values.showPagination,
        },
        favIcon: await handleImageUpload({ ...values.favIcon, name: 'favIcon' }, initialValues.favIcon),
        enableFavicon: values.faviconEnabled,
        headerLogo: await handleImageUpload(
          {
            ...values.headerLogo,
            name: 'header-logo',
          },
          initialValues.headerLogo
        ),
        enableHeaderLogo: values.headerLogoEnabled,
        toolbarLogo: await handleImageUpload(
          { ...values.toolbarLogo, name: 'toolbar-logo' },
          initialValues.toolbarLogo
        ),
        enableToolbarLogo: values.toolbarLogoEnabled,
        loginBackgroundImage: await handleImageUpload(
          { ...values.loginBackgroundImage, name: 'login-background-image' },
          initialValues.loginBackgroundImage
        ),
        enableLoginBackgroundImage: values.loginBackgroundImageEnabled,
        loginTheme: values.loginTheme,
        themeDashboard: {
          backgroundTheme: values.dashLayoutTheme,
          highlightBoxColor: values.dashLayoutHighlightColor,
          backgroundImage: await handleThemeDashboardUpload(values.dashLayoutBackgroundImage),
          divisions: values.dashLayoutDivisions,
          transparency: values.dashLayoutTransparency,
          hasMargin: values.dashLayoutItemsMargin,
          margin: values.dashLayoutMargin,
          hasContainerMargin: values.dashLayoutContainerMargin,
          containerMargin: values.dashLayoutContainerMarginValue,
          hasBoxShadow: values.dashLayoutItemsShadow,
          hasBorderRadius: values.dashLayoutBorderRadius,
        },
        properties,
      };

      await Api.ProjectManagement.save(values.id, {
        project,
        options,
        theme,
      });

      await findProjectsForManagement();
      UiMsg.ok(t('project.update.ok'));
      location.reload();
    } catch (e) {
      console.error(`Error on update project configurations`, e);
      UiMsg.error(t('project.update.error'), e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik onSubmit={onSave} initialValues={initialValues} enableReinitialize={true} validationSchema={FormSchema}>
      {({ isSubmitting, errors, submitCount }) => {
        useEffect(() => {
          if (submitCount < 1 || _.isEmpty(errors)) {
            return;
          }

          for (const [key, schema] of Object.entries(schemas)) {
            const schemaFields = schema.describe().fields;
            const tabErrors = Object.keys(errors).filter((errorKey) => Object.keys(schemaFields).includes(errorKey));
            if (!_.isEmpty(tabErrors)) {
              searchParams.set('currentTab', key);
              setSearchParams(searchParams);
              break;
            }
          }
        }, [submitCount]);

        return (
          <UiBlocker
            block={loading || isSubmitting}
            className={`ProjectManagement ${styles.accountsBlocker} ${className}`}
          >
            <BngForm>
              <PageLayout
                pageTitle={t('settings')}
                pageButton={() => (
                  <FloatActionButtonDropdown
                    selectedProject={selectedProject}
                    findProjectsForManagement={findProjectsForManagement}
                  />
                )}
                footerChild={currentTabKey !== 'accessTimeRestriction' ? () => <FooterComponent /> : undefined}
              >
                <div className={`${styles.navPanelWrapper}`}>
                  {/*TODO: Foi comentado o código abaixo, para ser implementado futuramente*/}
                  {/*GitHub Issue: https://github.com/sol7/bi-machine/issues/8611*/}
                  {/*<div className={`${styles.ProjectSelector}`}>*/}
                  {/*  <BngSelectSearch*/}
                  {/*    className={`${styles.ProjectSelectorDropDown}`}*/}
                  {/*    options={projectOpts}*/}
                  {/*    field={{*/}
                  {/*      value: selectedProject?.project.id,*/}
                  {/*    }}*/}
                  {/*    onChange={async (value) => {*/}
                  {/*      setLoading(true);*/}
                  {/*      try {*/}
                  {/*        await changeProject(value);*/}
                  {/*      } catch (e) {*/}
                  {/*        console.error('Error on changeProject()', e);*/}
                  {/*        UiMsg.ajaxError(null, e);*/}
                  {/*      } finally {*/}
                  {/*        setLoading(false);*/}
                  {/*      }*/}
                  {/*    }}*/}
                  {/*    defaultPreviewIcon="storage"*/}
                  {/*    dropdownIcon="expand_more"*/}
                  {/*    clearable={false}*/}
                  {/*  />*/}
                  {/*</div>*/}
                  <NavPanel
                    tabs={buildTabGroups({
                      addons: context.accountEnabledForBilling
                        ? context.billingAddons.map((addon) => addon.feature.key)
                        : context.addons,
                      dispatch,
                      isConsultant: context.permissions.isConsultant(),
                    })}
                    showSelector={false}
                    tabProps={{
                      project: selectedProject?.project,
                      master: selectedProject?.master,
                      options: selectedProject?.options,
                      theme: selectedProject?.theme,
                      account: selectedProject?.account,
                    }}
                  />
                </div>
              </PageLayout>
            </BngForm>
          </UiBlocker>
        );
      }}
    </Formik>
  );
}
