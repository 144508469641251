import FilterService from 'components/filter/FilterService';
import { buildUrl } from 'commonUtils';

class MetadataApi {
  exportDashboardMetadata = async ({ filters, content, ...params }) => {
    const filterRef = await FilterService.createFilterRef(filters);

    ['bigtableFilterModel', 'bigtableSortModel'].forEach((key) => {
      if (params[key]) {
        params[key] = encodeURIComponent(JSON.stringify(params[key]));
      }
    });

    const url = buildUrl('/spr/metadata/dashboard', {
      content,
      filterRef,
      ...params,
    });

    window.open(url, '_blank');
  };
}

export default MetadataApi;
