import styles from './OpenAiForm.module.css';

import React from 'react';
import { Field, FieldArray, useFormikContext } from 'formik';
import BngField from 'components/bng/form/BngField';
import BngSelect from 'components/bng/form/BngSelect';
import BngTextArea from 'components/bng/form/BngTextArea';
import BngInput from 'components/bng/form/BngInput';
import useTranslation from 'components/hooks/useTranslation';
import bngYup from 'components/bng/form/yup/BngYup';
import { BngAddButton } from 'components/bng/ui/BngAddButton';
import { BngTable } from 'components/bng/ui/BngTable';

export const typeOptions = [
  { value: 'code_interpreter', label: 'Code Interpreter' },
  { value: 'file_search', label: 'File Search' },
  { value: 'function', label: 'Function' },
];

export const modelOptions = [
  { value: 'gpt-4o', label: 'GPT-4o' },
  { value: 'gpt-3.5-turbo', label: 'GPT-3.5-Turbo' },
];

export const llmModelOptions = [{ value: 'OpenAI', label: 'OpenAI' }];

const SUPPORTED_LOCALES = ['pt_BR', 'pt_PT', 'en_US', 'es_ES'];

const TranslatedQuestionSchema = bngYup((yup) => {
  const QuestionDataSchema = yup.object({
    question: yup.string().required().default(''),
    caption: yup.string().required().default(''),
  });

  return yup.object({
    translations: yup
      .object(
        SUPPORTED_LOCALES.reduce((acc, el) => {
          acc[el] = QuestionDataSchema;
          return acc;
        }, {})
      )
      .required()
      .default({
        translations: SUPPORTED_LOCALES.reduce((acc, el) => {
          acc[el] = QuestionDataSchema.default();
          return acc;
        }, {}),
      }),
  });
});

const OpenAiFormSchema = bngYup((yup) => {
  return yup.object({
    type: yup
      .string()
      .required()
      .oneOf(typeOptions.map((option) => option.value))
      .default(''),
    model: yup
      .string()
      .required()
      .oneOf(modelOptions.map((options) => options.value))
      .default(''),
    temperature: yup.number().required().min(0).max(1).default(1),
    instruction: yup.string().required().default(''),
    props: yup
      .object({
        questions: yup.array(TranslatedQuestionSchema).min(1).default([]),
      })
      .default({ questions: [] }),
  });
});

function OpenAiForm({ editing = false }) {
  const { t } = useTranslation();
  const { values } = useFormikContext();

  return (
    <div className={`OpenAiForm ${styles.OpenAiForm}`}>
      <div className="d-flex gap-2">
        <Field
          rootClassName="w-50"
          name="name"
          component={BngField}
          required={true}
          disabled={editing}
          label={t('ada.ai.open.ai.tab.field.name')}
        />
        <Field
          rootClassName="w-50"
          name="model"
          component={BngField}
          inputComponent={BngSelect}
          required={true}
          label={t('ada.ai.open.ai.tab.field.model')}
          options={modelOptions}
        />
      </div>

      <div className="d-flex gap-2 mt-2">
        <Field
          rootClassName="w-50"
          name="description"
          component={BngField}
          required={true}
          label={t('ada.ai.open.ai.tab.field.description')}
        />
        <Field
          rootClassName="w-50"
          name="temperature"
          component={BngField}
          type="number"
          min={0}
          max={1}
          step={0.1}
          required={true}
          label={t('ada.ai.open.ai.tab.field.temperature')}
          onKeyDown={(event) => {
            if (event.key !== 'ArrowUp' && event.key !== 'ArrowDown') {
              event.preventDefault();
            }
          }}
        />
      </div>

      <div className="d-flex gap-2 mt-2">
        <Field
          rootClassName="w-50"
          name="type"
          component={BngField}
          inputComponent={BngSelect}
          required={true}
          label={t('ada.ai.open.ai.tab.field.type')}
          options={typeOptions}
        />
        <Field
          rootClassName="w-50"
          name="assistantKey"
          component={BngField}
          required={true}
          disabled={editing}
          label={t('ada.ai.open.ai.tab.field.assistantKey')}
        />
      </div>

      <Field
        rootClassName="mt-2"
        name="instruction"
        component={BngField}
        inputComponent={BngTextArea}
        required={true}
        label={t('ada.ai.open.ai.tab.field.instruction')}
      />
      <hr />
      <FieldArray
        name="props.questions"
        render={(arrayHelpers) => (
          <div className={`DivOpenAiFormBngIconButton ${styles.DivOpenAiFormBngIconButton}`}>
            <BngAddButton
              className={`OpenAiFormBngIconButton ${styles.OpenAiFormBngIconButton}`}
              onClick={() => arrayHelpers.push({
                translations: SUPPORTED_LOCALES.reduce((acc, locale) => {
                  acc[locale] = { question: '', caption: '' };
                  return acc;
                }, {})
              })}
            >
              <span>{t('add')}</span>
            </BngAddButton>

            <div style={{ overflowY: 'auto', width: '100%', border: '0.1px solid #e6e6e6' }}>
              <BngTable
                rows={values.props.questions || []}
                cols={[
                  {
                    key: 'caption',
                    label: (
                      <>
                        {t('ada.ai.open.ai.tab.field.question')} <span style={{ color: 'red' }}>*</span>
                      </>
                    ),
                    render: (row, idx) => {
                      return (
                        <div>
                          {SUPPORTED_LOCALES.map(locale => (
                            <Field
                              key={locale}
                              name={`props.questions.${idx}.translations.${locale}.caption`}
                              component={BngField}
                              label={locale}
                              required
                            />
                          ))}
                        </div>
                      );
                    },
                  },
                  {
                    key: 'question',
                    label: (
                      <>
                        {t('ada.ai.open.ai.tab.field.question.chatGPT')} <span style={{ color: 'red' }}>*</span>
                      </>
                    ),
                    render: (row, idx) => {
                      return (
                        <div>
                          {SUPPORTED_LOCALES.map(locale => (
                            <Field
                              key={locale}
                              name={`props.questions.${idx}.translations.${locale}.question`}
                              component={BngField}
                              label={locale}
                              required
                            />
                          ))}
                        </div>
                      );
                    },
                  },
                  {
                    key: 'action',
                    label: t('ada.ai.open.ai.tab.field.action'),
                    size: 70,
                    render: (row, idx) => (
                      <div className="remove-property-button" onClick={() => arrayHelpers.remove(idx)}>
                        {t('delete')}
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        )}
      />
    </div>
  );
}

export const OpenAiAssistantModel = {
  component: OpenAiForm,
  schema: OpenAiFormSchema,
};
