import React from "react";

import BigTableApp from "./BigTableApp";
import LoadingCenter from "components/ui/loading/LoadingCenter";
import ContextEnhancer from "components/ContextEnhancer";
import Api from "components/Api";

class BigTable extends React.Component {

    static defaultProps = {
        id: null,
        createMode: false,
        bigTable: null,
        filters: null,
        viewOnly: false,
        onLoadData: _.noop
    }

    state = {
        loading: true,
        bigTable: null,
        hasError: false,
    };

    static getDerivedStateFromError(error) {
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        console.error(`BigTable component catch an error`, error, errorInfo);
    }

    async componentDidMount() {
        await this.load();
    }

    async load() {
        try {
            let bigTable = this.props.bigTable;
            if (this.props.id) {
                bigTable = await Api.BigTable.findById(this.props.id);
            }
            this.setState({
                bigTable,
            });
        } catch (e) {
            console.error(e);
        } finally {
            this.setState({loading: false});
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="fill-w fill-h">
                    {this.props.context.msg.t('BigTable.pageLoadError')}
                </div>
            );
        }

        if (this.state.loading) {
            return (
                <div className="fill-w fill-h">
                    <LoadingCenter/>
                </div>
            );
        }

        if (this.state.bigTable) {
            return (
                <BigTableApp reload={() => this.load()}
                             createMode={this.props.createMode}
                             filters={this.props.filters}
                             viewOnly={this.props.viewOnly}
                             bigTable={this.state.bigTable}
                             onLoadData={this.props.onLoadData}
                />
            );
        } else {
            return <BigTableApp/>;
        }
    }
}

export default ContextEnhancer(BigTable);
