import styles from './AccountsPage.module.css';
import TUTORIAL_IMG_1 from './assets/ada.accounts.slide.1.png';
import TUTORIAL_IMG_2 from './assets/ada.accounts.slide.2.png';
import TUTORIAL_IMG_3 from './assets/ada.accounts.slide.3.png';
import TUTORIAL_IMG_4 from './assets/ada.accounts.slide.4.png';

import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import PageLayout from 'components/bng/pages/common/layout/PageLayout';
import useBimContext from 'components/hooks/useBimContext';
import Api from 'components/Api';
import Icon from 'components/ui/common/Icon';
import AccFinanceTab from 'components/ui/accounts/tabs/AccFinanceTab';
import AccProposalsTab from 'components/ui/accounts/tabs/AccProposalsTab';
import AccUsersTab from 'components/ui/accounts/tabs/AccUsersTab';
import AccStructuresTab from 'components/ui/accounts/tabs/AccStructuresTab';
import AccProjectsTab from 'components/ui/accounts/tabs/AccProjectsTab';
import AccAddonsTab from 'components/ui/accounts/tabs/AccAddonsTab';
import AccAppKeysTab from 'components/ui/accounts/tabs/AccAppKeysTab';
import PublisherFullTab from 'components/ui/accounts/tabs/PublisherFullTab';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import { UiBlocker } from 'components/bng/ui/UiBlocker';
import useQueryParams from 'components/hooks/useQueryParams';
import useTranslation from 'components/hooks/useTranslation';
import UiMsg from 'components/ui/UiMsg';
import useFetchData from 'components/hooks/useFetchData';
import useBimNavigate from 'components/hooks/useBimNavigate';
import { BetaTag } from 'components/bng/ui/BngTag';
import BngAda from 'components/bng/ui/BngAda/BngAda';
import BngMultiStep from 'components/bng/ui/BngMultiStep';
import { WhatsAppConsumption } from 'components/ui/whatsapp/WhatsAppDialog';
import EditObject from 'components/ui/edit-object/EditObject';
import AddonType from 'components/bng/accounts/AddonType';
import AccTraceabilityTab from 'components/ui/accounts/tabs/AccTraceabilityTab';
import NavPanel from 'components/ui/accounts/NavPanel';
import AccAdaAiTab from 'components/ui/accounts/tabs/AccAdaAiTab';

const ACC_QUERY_PARAM = 'currentAccount';

const checkAddonForAccount = ({ account, addonKeys }) => {
  const addons = account.activeForBilling ? account.billingAddons.activeAddons : account.addons;
  return addons.some((addon) => addonKeys.includes(addon.key ?? addon.addon));
};

const TAB_GROUPS = [
  {
    key: 'finance',
    label: 'accountTab.finance',
    component: AccFinanceTab,
    icon: 'credit_card',
    shouldDisable: ({ account, context }) => !account.activeForBilling && !context.user.accountManager,
    soonTag: true,
    children: [
      { title: 'contracted.plan', anchorId: 'AccFinanceTab-plan' },
      { title: 'additionals', anchorId: 'AccFinanceTab-contract' },
      { title: 'consumption', anchorId: 'AccFinanceTab-consumption' },
      { title: 'addons', anchorId: 'AccFinanceTab-addons' },
      { title: 'invoices.and.histories', anchorId: 'AccFinanceTab-invoices' },
    ],
  },
  {
    key: 'proposals',
    label: 'accountTab.proposals',
    component: AccProposalsTab,
    icon: 'gavel',
    shouldDisable: ({ account, context }) => !account.activeForBilling && !context.user.accountManager,
    soonTag: true,
  },
  {
    key: 'users',
    label: 'accountTab.users',
    component: AccUsersTab,
    icon: 'people',
  },
  {
    key: 'structures',
    label: 'accountTab.structures',
    component: AccStructuresTab,
    icon: 'cloud',
  },
  {
    key: 'projects',
    label: 'accountTab.projects',
    component: AccProjectsTab,
    icon: 'storage',
  },
  {
    key: 'addons',
    label: 'accountTab.addons',
    component: AccAddonsTab,
    icon: 'extension',
    children: [{ title: 'addon.whats.api.name', anchorId: '' }],
    hide: true,
  },
  {
    key: 'extensions',
    label: 'accountTab.extensions',
    component: PublisherFullTab,
    icon: 'extension',
    expandOnClick: true,
    shouldDisable: ({ account, context }) =>
      !checkAddonForAccount({
        account,
        addonKeys: [AddonType.WHATS_APP.key, AddonType.PUBLISHER_FULL.key, AddonType.ADA_AI.key],
      }),
    children: [
      {
        title: 'publisher.full',
        key: 'publisher',
        component: PublisherFullTab,
        shouldNotRender: ({ account }) =>
          !checkAddonForAccount({
            account,
            addonKeys: [AddonType.PUBLISHER_FULL.key],
          }),
      },
      {
        title: 'whatsapp',
        key: 'whatsapp',
        component: WhatsAppConsumption,
        shouldNotRender: ({ account }) => !checkAddonForAccount({ account, addonKeys: [AddonType.WHATS_APP.key] }),
      },
      {
        title: 'traceability',
        key: 'traceability',
        component: AccTraceabilityTab,
        shouldNotRender: ({ account }) =>
          !checkAddonForAccount({
            account,
            addonKeys: [AddonType.TRACEABILITY.key],
          }),
      },
      {
        title: 'Ada AI',
        key: 'Ada AI',
        component: AccAdaAiTab,
        shouldNotRender: ({ account }) => !checkAddonForAccount({ account, addonKeys: [AddonType.ADA_AI.key] }),
      },
    ],
  },
  {
    key: 'appKeys',
    label: 'accountTab.appKeys',
    component: AccAppKeysTab,
    icon: 'key',
    hide: false,
  },
];

function AccountsPageMultiStepInfo({ onFinish }) {
  const { t } = useTranslation();
  const handleNextClick = async ({ isLastStep }) => {
    if (isLastStep) {
      onFinish();
    }
  };

  return (
    <BngMultiStep
      className={`AccountsPageMultiStepInfo ${styles.AccountsPageMultiStepInfo}`}
      prepend={null}
      overlay={true}
      onNextClick={handleNextClick}
    >
      <BngMultiStep.Step>
        <img src={TUTORIAL_IMG_1} alt={t('accounts.ada.tutorial.step1.title')} />
        <BngMultiStep.Title>
          <div className={styles.tutorialStepTitle}>
            {t('accounts.ada.tutorial.step1.title')}
            <BetaTag />
          </div>
        </BngMultiStep.Title>
        <BngMultiStep.Subtitle>{t('accounts.ada.tutorial.step1.description')}</BngMultiStep.Subtitle>
      </BngMultiStep.Step>
      <BngMultiStep.Step>
        <img src={TUTORIAL_IMG_2} alt={t('accounts.ada.tutorial.step2.title')} />{' '}
        <BngMultiStep.Title>
          <div className={styles.tutorialStepTitle}>
            {t('accounts.ada.tutorial.step2.title')}
            <Icon icon={'monetization_on'} />
          </div>
        </BngMultiStep.Title>
        <BngMultiStep.Subtitle>{t('accounts.ada.tutorial.step2.description')}</BngMultiStep.Subtitle>
      </BngMultiStep.Step>
      <BngMultiStep.Step>
        <img src={TUTORIAL_IMG_3} alt={t('accounts.ada.tutorial.step3.title')} />{' '}
        <BngMultiStep.Title>
          <div className={styles.tutorialStepTitle}>
            {t('accounts.ada.tutorial.step3.title')}
            <Icon icon={'people'} />
          </div>
        </BngMultiStep.Title>
        <BngMultiStep.Subtitle>{t('accounts.ada.tutorial.step3.description')}</BngMultiStep.Subtitle>
      </BngMultiStep.Step>
      <BngMultiStep.Step>
        <img src={TUTORIAL_IMG_4} alt={t('accounts.ada.tutorial.step4.title')} />{' '}
        <BngMultiStep.Title>
          <div className={styles.tutorialStepTitle}>
            {t('accounts.ada.tutorial.step4.title')}
            <Icon icon={'settings'} />
          </div>
        </BngMultiStep.Title>
        <BngMultiStep.Subtitle>
          {t('accounts.ada.tutorial.step4.description')}
          <a
            className={styles.tutorialLink}
            href={t('accounts.ada.tutorial.step4.link')}
            rel="noreferrer"
            target="_blank"
          >
            {t('accounts.ada.tutorial.step4.link.text')}
          </a>
        </BngMultiStep.Subtitle>
      </BngMultiStep.Step>
    </BngMultiStep>
  );
}

export default function AccountsPage() {
  const context = useBimContext();
  const { t } = useTranslation();
  const { queryParams } = useQueryParams({ listenToEvents: true });

  const navigate = useBimNavigate();
  const [loading, setLoading] = useState(false);
  const [availableAccounts, setAvailableAccounts] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [adaVisible, setAdaVisible] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);

  const currentAccountId =
    (searchParams.has(ACC_QUERY_PARAM) ? _.parseInt(searchParams.get(ACC_QUERY_PARAM)) : null) ?? context.accountId;

  const { data: account, reload: refreshAccount } = useFetchData(async () => {
    if (!currentAccountId) {
      return null;
    }

    return await Api.Account.findAccount(currentAccountId);
  }, [currentAccountId]);

  const fetchAccounts = async () => {
    setLoading(true);
    try {
      const accounts = await Api.Account.findAvailable();

      if (accounts.length === 0) {
        navigate('/errors/403');
        return [];
      }

      const accountToSelect = accounts.find((account) => account.id === currentAccountId) ?? accounts[0];
      if (accountToSelect.id !== currentAccountId) {
        selectAccount(accountToSelect.id);
      }

      const accountOpts = accounts.map((account) => {
        return {
          value: account.id,
          label: account.name,
          isMaster: account.accountMasterId === context.user.id,
        };
      });

      accountOpts.sort((a, b) => {
        const fa = a.label.toLowerCase();
        const fb = b.label.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
      setAvailableAccounts(accountOpts);
    } catch (e) {
      console.error('Error on fetchAccounts()', e);
      UiMsg.ajaxError(null, e);
    } finally {
      setLoading(false);
    }
  };

  const selectAccount = async (accountId) => {
    searchParams.set(ACC_QUERY_PARAM, accountId);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    fetchAccounts();
  }, []);

  return (
    <div className={`AccountsPage ${styles.AccountsPage}`}>
      <UiBlocker block={loading} className={styles.accountsBlocker}>
        {!!account && (
          <PageLayout
            pageTitle={t('account.management')}
            titleComponent={() => {
              return <BetaTag />;
            }}
            headerButtons={() => (
              <React.Fragment>
                {context.user.accountManager && (
                  <EditObject
                    title={t('Billing')}
                    checked={account.activeForBilling}
                    onChange={async () => {
                      try {
                        await Api.Account.toggleAccountEnabledInBilling(currentAccountId);
                        await refreshAccount();
                      } catch (e) {
                        console.error('Error on toggling account enabled for Billing', e);
                        if (e.response?.status === 403) {
                          UiMsg.error(t('forbidden.action'));
                        } else if (e.response?.status === 404) {
                          UiMsg.error(t('error.fetching.data', t('account')));
                        } else {
                          UiMsg.error(t('save_error', t('account')), e);
                        }
                      }
                    }}
                  />
                )}
                <BngIconButton
                  icon={'manage_accounts'}
                  title={t('manage.users')}
                  onClick={() =>
                    navigate(
                      Api.buildUrl('/spr/bng/accounts', {
                        currentTab: 'users',
                        breadcrumb: true,
                        currentAccount: currentAccountId,
                      }),
                    )
                  }
                />
                <BngIconButton
                  icon={'autorenew'}
                  title={t('title_refresh')}
                  onClick={async () => {
                    try {
                      await Api.Account.clearBillingCache({ accountId: account.id });
                      window.location.reload();
                    } catch (e) {
                      console.error('Error on clearBillingCache()', e);
                      UiMsg.error(t('cache.clear.error'));
                    }
                  }}
                />
              </React.Fragment>
            )}
          >
            <div className={`${styles.navPanelWrapper}`}>
              <NavPanel
                account={account}
                accountOpts={availableAccounts}
                onSelectAccount={selectAccount}
                queryParams={queryParams}
                tabs={TAB_GROUPS}
                defaultTab={
                  TAB_GROUPS.filter(
                    (tab) =>
                      !(
                        tab.shouldDisable?.({
                          account,
                          context,
                        }) ?? false
                      ),
                  )[0]
                }
                tabProps={{
                  isMasterOfCurrentAccount: availableAccounts.find((acc) => acc.value === currentAccountId)?.isMaster,
                  fetchAccounts,
                  availableAccounts,
                }}
              />
            </div>
          </PageLayout>
        )}
      </UiBlocker>
      <BngAda
        className={styles.AccountsPageAda}
        title={t('accounts.page.ada.title')}
        showInfo={!showTutorial}
        options={[
          {
            icon: 'help',
            label: t('access.tutorial'),
            onClick: () => {
              setShowTutorial(true);
            },
          },
          {
            icon: 'arrow_right_alt',
            label: t('BigTableAnalysisHelp.adaLinks.label1'),
            onClick: () => window.open(t('accounts.ada.tutorial.step4.link'), '_blank'),
          },
        ]}
        buttonPressed={adaVisible}
        onClick={() => {
          setAdaVisible(!adaVisible);
        }}
      ></BngAda>
      {showTutorial && (
        <AccountsPageMultiStepInfo
          onFinish={() => {
            setShowTutorial(false);
            setAdaVisible(false);
          }}
        />
      )}
    </div>
  );
}
